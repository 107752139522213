import {Injectable} from '@angular/core';
import {defaultAccountSettings, MainState} from '@app/store/main.store';
import {TranslocoService} from '@ngneat/transloco';
import {Store} from '@ngxs/store';
import moment from 'moment';
import {ca_ES, en_US, es_ES, NzI18nService, pt_PT} from 'ng-zorro-antd/i18n';

@Injectable()
export class LanguageService {

  constructor(private translateService: TranslocoService, private i18n: NzI18nService, private store: Store) {}

  setGlobalLanguage(): void {
    const language = this.store.selectSnapshot(MainState.accountSettings)?.locale ?? defaultAccountSettings.locale;

    this.translateService.setActiveLang(language);
    switch (language) {
      case 'es':
        this.i18n.setLocale(es_ES);
        moment.locale('es');
        break;
      case 'en':
        this.i18n.setLocale(en_US);
        moment.locale('en');
        break;
      case 'pt':
        this.i18n.setLocale(pt_PT);
        moment.locale('pt');
        break;
      case 'ca':
        this.i18n.setLocale(ca_ES);
        moment.locale('ca');
        break;
    }
  }

}
