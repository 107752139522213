export interface CheckBoxGroupSection {
  id: string;
  title: string;
  subSections: CheckBoxGroupSubSection[];
}
export interface CheckBoxGroupSubSection {
  id: string;
  title: string;
  items: any[];
}

export interface CheckBoxGroupModel {
  sections: CheckBoxGroupSection[];
}

class AzCheckBoxGroupItem {

  id: string;
  includes: string[];
  section: string;
  subSection: string;
  title: string;
  readOnly: boolean;
  constructor(section: string, subSection: string, id: string, includes?: string[]) {
    this.id = id;
    this.section = section;
    this.subSection = subSection;
    this.includes = includes || [];
    this.title = 'permisos.' + id;
  }

}

export enum PermisosGenerales {
  MODIFICACION_EMISORES = 'modificacion_emisores',
  MODIFICACION_DATOS_INTRODUCIDOS = 'modificacion_datos_introducidos',
  ACCESO_FACTORES_EMISION = 'acceso_factores_emision',
  MODIFICACION_FACTORES_EMISION = 'modificacion_factores_emision',
  ACCESO_FUENTES = 'acceso_fuentes',
  MODIFICACION_FUENTES = 'modificacion_fuentes',
  ACCESO_TIPOS_EMISOR = 'acceso_tipos_emisor',
  MODIFICACION_TIPOS_EMISOR = 'modificacion_tipos_emisor'
}

export type Permiso = PermisosGenerales;
export const Permiso = PermisosGenerales;

export const PREFIJO_PERMISO_ACCESO = 'acceso_';
export const PREFIJO_PERMISO_MODIFICAR = 'modificar_';

export const CheckboxPermisosGenerales: AzCheckBoxGroupItem[] = [
  new AzCheckBoxGroupItem('general', 'emisores', PermisosGenerales.MODIFICACION_EMISORES),
  new AzCheckBoxGroupItem('general', 'datos_introducidos', PermisosGenerales.MODIFICACION_DATOS_INTRODUCIDOS),
  new AzCheckBoxGroupItem('general', 'factores_emision', PermisosGenerales.ACCESO_FACTORES_EMISION),
  new AzCheckBoxGroupItem('general', 'factores_emision', PermisosGenerales.MODIFICACION_FACTORES_EMISION, [PermisosGenerales.ACCESO_FACTORES_EMISION]),
  new AzCheckBoxGroupItem('general', 'fuentes', PermisosGenerales.ACCESO_FUENTES),
  new AzCheckBoxGroupItem('general', 'fuentes', PermisosGenerales.MODIFICACION_FUENTES, [PermisosGenerales.ACCESO_FUENTES]),
  new AzCheckBoxGroupItem('general', 'tipos_emisor', PermisosGenerales.ACCESO_TIPOS_EMISOR),
  new AzCheckBoxGroupItem('general', 'tipos_emisor', PermisosGenerales.MODIFICACION_TIPOS_EMISOR, [PermisosGenerales.ACCESO_TIPOS_EMISOR])
];

export function getFeaturesPermisosModel(checkboxs: AzCheckBoxGroupItem[]): CheckBoxGroupModel {
  const sections = {};
  const subSections = {};

  checkboxs.forEach((value) => {
    let section: CheckBoxGroupSection = sections[value.section];
    let subSection: CheckBoxGroupSubSection = subSections[value.subSection];

    if (!section) {
      section = {id: value.section, subSections: [], title: 'feature-groups.' + value.section};
      sections[value.section] = section;
    }

    if (!subSection) {
      subSection = {id: value.subSection, items: [], title: 'features.' + value.subSection};
      subSections[value.subSection] = subSection;
      section.subSections.push(subSection);
    }

    const item = {value: value.id, label: value.title, includes: value.includes, readOnly: value.readOnly};

    subSection.items.push(item);
  });

  return {sections: Object.values(sections)};
}
