import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AuthState } from "@app/store/auth.store";
import { MainState } from "@app/store/main.store";
import { NgxsModule } from "@ngxs/store";
import { AngularSvgIconModule } from "angular-svg-icon";
import { SharedModule } from "primeng/api";
import { AuthGuard } from "./guards/auth.guard";

@NgModule({
    declarations: [],
    imports: [
      CommonModule,
      RouterModule,
      SharedModule,
      NgxsModule.forFeature([AuthState, MainState]),
      AngularSvgIconModule.forRoot()
    ],
    exports: [],
    providers: [AuthGuard]
  })
  export class CoreModule { }