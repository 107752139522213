import { Component } from '@angular/core';
import { FillFunciones, FillPlanes, FillRoles, MainState } from "@app/store/main.store";
import { Select, Store } from '@ngxs/store';
import { take } from 'rxjs/operators';
import { PlanModel } from '@shared/models/plan.model';
import { PlanesUserService } from '@shared/services/planes-user.service';
import { EnumsService } from '@app/shared/services/enums.service';
import { RolModel } from '@app/shared/models/rol.model';
import { FuncionModel } from "@shared/models/funcion.model";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html'
})
export class IndexComponent {

  @Select(MainState.theme) theme$;

  constructor(
    private store: Store,
    private planesService: PlanesUserService,
    private enumsService: EnumsService,
  ) {
    this.planesService
      .getAll()
      .pipe(take(1))
      .subscribe((planes: PlanModel[]) => {
        this.store.dispatch(new FillPlanes(planes));
      });

    this.planesService
      .allFunciones()
      .pipe(take(1))
      .subscribe((funciones: FuncionModel[]) => {
        this.store.dispatch(new FillFunciones(funciones));
      });

    this.enumsService
      .getRoles()
      .pipe(take(1))
      .subscribe((roles: RolModel[]) => {
        this.store.dispatch(new FillRoles(roles));
      });
  }

}
