import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Actions, ofActionSuccessful, Store} from '@ngxs/store';
import {pathsNotAllowedRedirection} from '@shared/config';
import {LanguageService} from '@shared/services/language.service';
import {LoginRoutingService} from '@shared/services/login-routing.service';
import {getRedirectData} from '@shared/utils';
import {AuthState, LogoutAction, StartLogoutAction} from '@store/auth.store';
import {FillUsuario} from '@store/main.store';
import {registerLocaleData} from '@angular/common';
import ca from '@angular/common/locales/ca';
import en from '@angular/common/locales/en';
import es from '@angular/common/locales/es';
import pt from '@angular/common/locales/pt';
import {take} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import {TranslocoService} from '@ngneat/transloco';

registerLocaleData(es);
registerLocaleData(en);
registerLocaleData(pt);
registerLocaleData(ca);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  routeTitle = '';

  constructor(
    private languageService: LanguageService,
    private actions$: Actions,
    private loginRoutingService: LoginRoutingService,
    private router: Router,
    private store: Store,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslocoService
  ) {}
  ngOnInit(): void {
    this.languageService.setGlobalLanguage();
    this.setGlobalActionsSideEffects();
    this.setRouterEvents();
  }

  private setGlobalActionsSideEffects() {
    this.actions$.pipe(ofActionSuccessful(FillUsuario, LogoutAction, StartLogoutAction)).subscribe((action) => {
      if (action instanceof FillUsuario) {
        this.loginRoutingService.enrouteUser();
        this.languageService.setGlobalLanguage();
      }

      if (action instanceof StartLogoutAction) {
        this.store.dispatch(new LogoutAction());
      }
    });
  }

  private setRouterEvents(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const token = this.store.selectSnapshot(AuthState.token$);
        const blankPage = event.url === '/';

        if (blankPage && token) {
          this.loginRoutingService.enrouteUser(true);

          return;
        }

        if (!event.url.includes('/login') && !blankPage && !token) {
          const url = getRedirectData(event.url);
          let isPathAllowedToRedirect = true;

          pathsNotAllowedRedirection.forEach((path) => {
            if (event.url.includes(path)) {
              isPathAllowedToRedirect = false;

              return false;
            }
          });

          if (isPathAllowedToRedirect) {
            this.router.navigate(['/login'], {
              queryParams: {
                redirect_url: url.redirect_url,
                redirect_params: new URLSearchParams(getRedirectData(event.url).redirect_params).toString()
              }
            });
          }
        }
      }

      if (event instanceof NavigationEnd) {
        let currentRoute: ActivatedRoute = this.activatedRoute.root;

        this.routeTitle = '';
        do {
          const childrenRoutes = currentRoute.children;

          currentRoute = null;
          childrenRoutes.forEach((route) => {
            if (route.outlet === 'primary') {
              if (route.snapshot.data.title) {
                this.routeTitle = route.snapshot.data.title;
              }

              currentRoute = route;
            }
          });
        } while (currentRoute);

        this.translateService
          .selectTranslate(this.routeTitle)
          .pipe(take(1))
          .subscribe((title) => this.titleService.setTitle(this.buildTitle(this.translateService.translate(title))));
      }
    });
  }

  buildTitle(title: string): string {
    return this.translateService.translate('common.huella-carbono') + (title ? ` - ${title}` : '');
  }

}
