import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/markdown/markdown';
import moment from 'moment';
import set from 'lodash/set';

export enum PasterColumnType {
  number = 'number',
  boolean = 'boolean',
  date = 'date',
  string = 'string'
}

export interface PasterModelItem {
  name: string;
  required: boolean;
  type: PasterColumnType;
  regex?: RegExp;
  field?: string;
}

export interface PasterModel {
  [id: string]: {fields: {[id: string]: PasterModelItem}};
}

const detectionRegex = {
  date: [
    {regex: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/, format: 'DD/MM/YYYY'},
    {regex: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{2}$/, format: 'DD/MM/YY'}
  ],
  number: [{regex: /^\d+$/}, {regex: /^-?(?:\d+|\d{1,3}(?:\.\d{3})+)(\,\d+)?$/}, {regex: /^-?(?:\d+|\d{1,3}(?:\,\d{3})+)(\.\d+)?$/}]
};

interface PasterColumns {
  [id: string]: PasterDetectedColumn;
}

interface PasterDetectedColumn {
  id?: string;
  field?: string;
  value: string;
  name: string;
  type: PasterColumnType;
  editing?: boolean;
  format?: string;
  skip?: boolean;
}

@Component({
  selector: 'app-paste-import',
  templateUrl: './paste-import.component.html'
})
export class PasteImportComponent {

  @HostBinding('class') class = 'flex flex-col h-full';
  status: 'PASTING' | 'MAPPING' | 'REVIEWING' | 'FINISHED' = 'PASTING';
  allowedColumnTypes = Object.keys(PasterColumnType);
  content: string;
  rowSeparator = '\n';
  columnSeparator = '\t';
  firstRowHeaders = true;

  headers = {};
  @Input() showFooter = true;

  @Output() onSend: EventEmitter<any[]> = new EventEmitter();
  @Input() model: {
    fields: {[id: string]: PasterModelItem};
  } = {
    fields: {
      codigo: {
        name: 'Codigo punto de suministro',
        required: true,
        type: PasterColumnType.string,
        regex: /ES0\w+/gim
      },
      fechaInicio: {
        name: 'Fecha de inicio',
        required: true,
        type: PasterColumnType.date
      },
      fechaFin: {
        name: 'Fecha de fin',
        required: true,
        type: PasterColumnType.date
      },
      nombre: {
        name: 'Nombre de tarifa',
        required: true,
        type: PasterColumnType.string
      },
      codigoComercializadora: {
        name: 'Codigo de comercializadora',
        type: PasterColumnType.string,
        required: true
      }
    }
  };

  // mapping data
  parsedContent: {[id: string]: string}[];
  detectedColumns: PasterColumns = {};
  selectedFields: string[] = [];

  data = [];

  fieldIsAssigned(fieldId: string): boolean {
    return Object.values(this.detectedColumns).findIndex((value) => value.field === fieldId) >= 0;
  }

  parseData(): void {
    const data = [];
    const rows = this.content.split(this.rowSeparator);

    if (rows.length > 0) {
      let dataStart = 0;

      if (this.firstRowHeaders) {
        const headerRow = rows[dataStart];

        headerRow.split(this.columnSeparator).forEach((value, index) => {
          this.headers['columm_' + index] = value;
        });
        dataStart++;
      } else {
        const headerRow = rows[dataStart];

        headerRow.split(this.columnSeparator).forEach((value, index) => {
          this.headers['columm_' + index] = 'columm_' + index;
        });
      }

      const firstRow = rows[dataStart];

      firstRow.split(this.columnSeparator).forEach((value, index) => {
        this.detectedColumns['columm_' + index] = {
          value,
          type: PasterColumnType.string,
          name: this.headers['columm_' + index]
        };
        this.assignFieldAuto(this.detectedColumns['columm_' + index], value);
      });
      rows.splice(0, dataStart);
      rows.forEach((value) => {
        const cols = {};

        value.split(this.columnSeparator).forEach((value1, index1) => {
          cols['columm_' + index1] = value1;
        });
        data.push(cols);
      });
      this.parsedContent = data;
    }
  }

  areFieldsOk() {
    for (const fieldId in this.model.fields) {
      if (this.model.fields.hasOwnProperty(fieldId)) {
        const detectedColumn = Object.values(this.detectedColumns).find((value) => value.id === fieldId);

        if (this.model.fields[fieldId].required) {
          if (!detectedColumn) {
            return false;
          }

          if (!detectedColumn.value) {
            return false;
          }

          if (!detectedColumn.type) {
            return false;
          }

          if (!this.parseColumnValue(detectedColumn, detectedColumn.value)) {
            return false;
          }
        }
      }
    }

    return true;
  }

  assignFieldAuto(column: PasterDetectedColumn, value: string): void {
    const field = this.testRegexAutoField(value);

    if (field) {
      column.id = field.id;
      column.field = field.field ? field.field : field.id;
      column.format = field.format;
      column.type = field.type;
    }
  }

  testRegexAutoField(value) {
    for (const key in this.model.fields) {
      if (this.model.fields.hasOwnProperty(key) && !this.fieldIsAssigned(key)) {
        let regex = null;

        if (this.model.fields[key].regex) {
          regex = new RegExp(this.model.fields[key].regex);
          if (regex && regex.test(value)) {
            return {id: key, type: this.model.fields[key].type};
          }
        } else if (detectionRegex[this.model.fields[key].type] && detectionRegex[this.model.fields[key].type].length) {
          const regexes = detectionRegex[this.model.fields[key].type];

          for (const rgx of regexes) {
            regex = new RegExp(rgx.regex);
            if (regex && regex.test(value)) {
              return {
                id: key,
                type: this.model.fields[key].type,
                format: rgx.format,
                field: this.model.fields[key].field ? this.model.fields[key].field : undefined
              };
            }
          }
        }
      }
    }

    return null;
  }

  parseColumnValue(column: PasterDetectedColumn, value: string) {
    switch (column.type) {
      case PasterColumnType.boolean:
        return Boolean(value);
      case PasterColumnType.date:
        return moment(value, column.format);
      case PasterColumnType.number:
        if (column.format === 'en') {
          return Number(value.replace(',', ''));
        } else if (column.format === 'es') {
          return Number(value.replace('.', '').replace(',', '.'));
        } else {
          return Number(value);
        }

      case PasterColumnType.string:
        return value;
    }
  }

  processData(): void {
    this.parsedContent.forEach((row) => {
      const dataRow = {};

      Object.keys(row).forEach((value) => {
        if (row.hasOwnProperty(value)) {
          const column = this.detectedColumns[value];

          set(dataRow, column.field, this.parseColumnValue(column, row[value]));
        }
      });
      this.data.push(dataRow);
    });
  }

  next(): void {
    switch (this.status) {
      case 'PASTING':
        this.parseData();
        this.status = 'MAPPING';
        break;
      case 'MAPPING':
        this.processData();
        this.status = 'REVIEWING';
        break;
      case 'REVIEWING':
        this.onSend.emit(this.data);
        this.status = 'FINISHED';
        break;
    }
  }

  back(): void {
    switch (this.status) {
      case 'MAPPING':
        this.detectedColumns = {};
        this.parsedContent = [];
        this.status = 'PASTING';
        break;
      case 'REVIEWING':
        this.data = [];
        this.status = 'MAPPING';
        break;
    }
  }

}
