import {environment} from '@env/environment';
import {Injectable} from '@angular/core';
import {AbstractBackendService} from '@app/shared/interfaces/service-classes';
import {FactorEmisionModel} from '@shared/models/factor-emision.model';

@Injectable()
export class DefaultFactoresEmisionUserService extends AbstractBackendService<FactorEmisionModel, undefined> {

  buildUrlBase(): string {
    return `${environment.api.admin.defaultFactoresEmision.user}`;
  }

}
