import {environment} from '@env/environment';
import {Injectable} from '@angular/core';
import {AbstractPaginatedService} from '@app/shared/interfaces/service-classes';
import {DefaultTipoEmisorModel} from '@shared/models/default-tipo-emisor.model';

@Injectable()
export class DefaultTiposEmisorUserService extends AbstractPaginatedService<DefaultTipoEmisorModel, undefined> {

  buildUrlBase(): string {
    return `${environment.api.admin.defaultTiposEmisor.user}`;
  }

}
