import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AzModalConfig, AzModalRef, AzModalService, AzNotifierService, AzSelectItem, ModalBaseComponent} from '@azigrene/components';
import {TranslocoService} from '@ngneat/transloco';
import {take} from 'rxjs/operators';
import {DateFormats, DateTimeFormats} from '../../data/date-formats.data';
import {DynamicTablePropertiesTypeEnum} from '../../data/dynamic-table-properties-type.enum';
import {DynamicTableFieldModel} from '../../models/dynamic-table-property.model';
import {DynamicFieldSelectOptionsCreatorComponent} from '../dynamic-field-select-options-creator/dynamic-field-select-options-creator.component';
import {v4 as uuidv4} from 'uuid';
import {jsonClone} from '@shared/utils';

@Component({
  selector: 'app-dynamic-field-creator',
  templateUrl: './dynamic-field-creator.component.html'
})
export class DynamicFieldCreatorComponent extends ModalBaseComponent {

  form: FormGroup;
  field: DynamicTableFieldModel;
  tipoOptions: AzSelectItem[] = [];
  tipoEnum = DynamicTablePropertiesTypeEnum;
  selectOptions: string[] = [];
  dateFormatOptions: AzSelectItem[] = [];
  dateTimeFormatOptions: AzSelectItem[] = [];
  currentFields: DynamicTableFieldModel[];

  isEditMode = false;

  constructor(public config: AzModalConfig, public ref: AzModalRef, private formBuilder: FormBuilder, private translateService: TranslocoService, private modalService: AzModalService, private notifierService: AzNotifierService) {
    super(config, ref);
    this.tipoOptions = Object.values(DynamicTablePropertiesTypeEnum).map((value) => {
      return {
        label: this.translateService.translate(`components.dynamic-table.${value}`),
        value
      };
    });
    this.dateFormatOptions = DateFormats.map((format) => {
      return {label: format, value: format};
    });
    this.dateTimeFormatOptions = DateTimeFormats.map((format) => {
      return {label: format, value: format};
    });
    this.form = formBuilder.group({
      uuid: [uuidv4()],
      nombre: [null, [Validators.required]],
      tipo: [DynamicTablePropertiesTypeEnum.TEXTO, [Validators.required]],
      dateFormat: [null]
    });
    if (this.config.data?.field) {
      this.isEditMode = true;
      const property: DynamicTableFieldModel = this.config.data.field;

      this.form.patchValue(property);
      this.selectOptions = property?.opciones ? jsonClone(property.opciones) : [];
    }

    this.currentFields = this.config.data.currentFields;
  }

  createSelectOption(option: string, index: number): void {
    const ref = this.modalService.open(DynamicFieldSelectOptionsCreatorComponent, {
      data: {
        options: this.selectOptions.filter((selectedOption) => selectedOption !== option),
        option
      },
      styleClass: 'w-[25%]'
    });

    ref.onClose.pipe(take(1)).subscribe((newOption) => {
      if (newOption) {
        if (index !== null) {
          this.selectOptions[index] = newOption;
        } else {
          this.selectOptions.push(newOption);
        }

        this.form.markAsDirty();
      }
    });
  }

  deleteSelectOption(index: number): void {
    this.selectOptions.splice(index, 1);
    this.form.markAsDirty();
  }

  save(): void {
    const currentFields = jsonClone(this.currentFields);

    currentFields?.push(this.form.value);
    const isDuplicated = currentFields?.filter((f) => f.nombre === this.form.value.nombre && f.uuid !== this.form.value.uuid).length > 0;

    if (isDuplicated) {
      this.notifierService.showInfoError(this.translateService.translate('components.dynamic-table.error-duplicated'));

      return;
    }

    if (this.form.value.tipo === DynamicTablePropertiesTypeEnum.LISTAVALORES && (!this.selectOptions || this.selectOptions?.length === 0)) {
      this.notifierService.showInfoError(this.translateService.translate('misc.notifier.error-select-without-options'));

      return;
    }

    this.ref.close({...this.form.value, ...{opciones: this.selectOptions}});
  }

}
