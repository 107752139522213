import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AzNotifierService } from '@azigrene/components';
import { TranslocoService } from '@ngneat/transloco';
import { MainState } from '@app/store/main.store';

@Injectable()
export class PermisoGuard  {

  constructor(private store: Store, private notifierService: AzNotifierService, private translateService: TranslocoService) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const miembro = this.store.selectSnapshot(MainState.miembro);

    if (!miembro) {
      return false;
    }

    if (miembro.usuario.rolAplicacion === 'ADMIN' || miembro.rol.id === 1) {
      return true;
    }

    const permisos = miembro.permisos;
    const allowedPermiso = route.data.permiso;
    let permitido = false;

    if (allowedPermiso && permisos && permisos.length) {
      permitido = permisos.indexOf(allowedPermiso) >= 0;
    }

    if (!permitido) {
      this.notifierService.showInfoError(this.translateService.translate('misc.forbidden-descripcion'), this.translateService.translate('misc.forbidden-titulo'));
    }

    return permitido;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const miembro = this.store.selectSnapshot(MainState.miembro);

    if (!miembro) {
      return false;
    }

    if (miembro.usuario.rolAplicacion === 'ADMIN' || miembro.rol.id === 1) {
      return true;
    }

    const permisos = miembro.permisos;
    const allowedPermiso = route.data.permiso;
    let permitido = false;

    if (allowedPermiso && permisos && permisos.length) {
      permitido = permisos.indexOf(allowedPermiso) >= 0;
    }

    if (!permitido) {
      this.notifierService.showInfoError(this.translateService.translate('misc.forbidden-descripcion'), this.translateService.translate('misc.forbidden-titulo'));
    }

    return permitido;
  }


}

