import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Store} from '@ngxs/store';
import {hasPermisionEdit} from '@shared/utils';
import {Permiso} from '@shared/enums/permisos.enum';
import {MainState} from '@store/main.store';
import {RolAplicacion} from '@shared/enums/rol-aplicacion.enum';

@Component({
  selector: 'app-data-import-export',
  template: `
    <div class="inline-flex space-x-2 items-center">
      <ng-container *ngIf="showImport">
        <ng-container>
          <button id="data-export.import" [disabled]="!(isAdmin || (hasPermisionEdit && !isAdmin)) || disabled" azButton (click)="this.import.emit()" [azTooltip]="'misc.importar' | transloco" tooltipPosition="bottom">
            <az-icon icon="upload"></az-icon>
          </button>
        </ng-container>
      </ng-container>
      <az-overlay [template]="menuColumns" position="BOTTOM_RIGHT" [disabled]="disabled">
        <button id="data-export.export" azButton [azTooltip]="exportButtonName | transloco" tooltipPosition="bottom" [disabled]="disabled">
          <az-icon icon="download"></az-icon>
          <span>{{ exportButtonName | transloco }}</span>
        </button>
        <ng-template let-toppy #menuColumns>
          <div class="flex flex-col p-1">
            <button azButton level="tertiary" class="font-semibold" (click)="this.export.emit('XLSX'); toppy.close()">{{ 'misc.xlsx' | transloco }}</button>
            <button azButton level="tertiary" class="font-semibold" (click)="this.export.emit('CSV'); toppy.close()">{{ 'misc.csv' | transloco }}</button>
          </div>
        </ng-template>
      </az-overlay>
    </div>
  `,
  styles: []
})
export class DataImportExportComponent implements OnInit {

  @Output() export = new EventEmitter<'XLSX' | 'CSV'>();
  @Output() import = new EventEmitter();
  @Input() permisoModificar: Permiso;
  @Input() showImport = true;
  @Input() small = false;
  @Input() exportButtonName = 'misc.exportar';
  @Input() disabled = false;
  isAdmin = false;
  hasPermisionEdit = false;

  constructor(private store: Store) {
    const miembro = this.store.selectSnapshot(MainState.miembro);

    if (miembro && (miembro.usuario.rolAplicacion === RolAplicacion.ADMIN.codigo || miembro.rol.id === RolAplicacion.ADMIN.id)) {
      this.isAdmin = true;
    }
  }

  ngOnInit(): void {
    if (this.permisoModificar) {
      this.hasPermisionEdit = hasPermisionEdit(this.store, this.permisoModificar);
    }
  }

}
