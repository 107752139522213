import {Component} from '@angular/core';
import {AzModalConfig, AzModalRef, ModalBaseComponent} from '@azigrene/components';
import {PaginatedResponse, SearchRequest} from '@azigrene/searchrequest';
import {Observable} from 'rxjs';
import {EmisorModel} from '@shared/models/emisor.model';

@Component({
  selector: 'app-csv-copy-emisores',
  templateUrl: './csv-copy-emisores.component.html'
})
export class CsvCopyEmisoresComponent extends ModalBaseComponent {

  csvInput: string;
  isResumenSection = false;
  temporalSelection: EmisorModel[] = [];
  organismoCodigo: string;

  serviceCall: (sr) => Observable<PaginatedResponse<any>>;

  constructor(private ref: AzModalRef, private config: AzModalConfig) {
    super(config, ref);
    if (!config.data.serviceCall) {
      this.ref.close(false);
    }

    this.organismoCodigo = config.data.organismo;
  }

  async irResumen(): Promise<void> {
    const inputArray = this.csvInput.split(/[,\n]+/);

    inputArray.filter((item) => item.length > 200);
    const newSr = new SearchRequest().addFilter(this.config.data.codeFilter, inputArray);

    newSr.pagination = {page: 0, pageSize: 9999}; //quita el limite a 25 copiados maximo

    const resp = await this.config.data.serviceCall(newSr).toPromise();

    this.temporalSelection = resp.data;
    this.isResumenSection = true;
  }

  close(): void {
    this.ref.close(this.temporalSelection);
  }

}
