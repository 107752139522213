import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PaginatedResponse, SearchRequest} from '@azigrene/searchrequest';
import {Component, forwardRef, OnChanges, TemplateRef} from '@angular/core';
import {AzModalService} from '@azigrene/components';
import {Observable} from 'rxjs';
import {DataFilterDeclaration} from '@azigrene/data-manager';
import {filterDeclaration} from '@shared/components/search-emisor/search-emisor.querysearch';
import {EmisorModel} from '@shared/models/emisor.model';
import {TipoSeleccionEnum} from '@shared/components/seleccion/tipo-seleccion.enum';
import {SearchComponent} from '@shared/directives/search-component.directive';
import {EmisoresUserService} from '@shared/services/emisores-user.service';

@Component({
  selector: 'app-search-emisor',
  templateUrl: '../../templates/search-input/search-input-emisor.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchEmisorComponent),
      multi: true
    }
  ]
})
export class SearchEmisorComponent extends SearchComponent<EmisorModel> implements ControlValueAccessor, OnChanges {

  getLabelProperty(): string {
    return 'nombre';
  }
  getCodeProperty(): string {
    return 'codigo';
  }

  getSearchProperties(): string[] {
    return ['codigo', 'nombre'];
  }

  getIdFilter(): string {
    return 'id_in';
  }
  getCodeFilter(): string {
    return 'codigo_in';
  }

  constructor(private dialogService: AzModalService, private emisoresService: EmisoresUserService) {
    super(dialogService);
  }

  getFilterDeclarations(): {[id: string]: DataFilterDeclaration} {
    return filterDeclaration;
  }

  getObservable(): (sr: SearchRequest) => Observable<PaginatedResponse<EmisorModel>> {
    return (sr: SearchRequest) => this.emisoresService.getAllPaginated(sr);
  }

  getTipoSeleccion(): number {
    return TipoSeleccionEnum.EMISOR;
  }

  getListItemTemplate(): TemplateRef<HTMLElement> {
    return this.listItemTemplate;
  }

}
