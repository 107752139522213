import {Feature} from './features.enum';
import {PlanModel} from './plan.model';

export enum AvailableThemes {
  'default-theme' = 'default-theme',
  'red-theme' = 'red-theme',
  'orange-theme' = 'orange-theme',
  'yellow-theme' = 'yellow-theme',
  'blue-theme' = 'blue-theme',
  'green-theme' = 'green-theme',
  'indigo-theme' = 'indigo-theme',
  'clear-red-theme' = 'clear-red-theme',
  'clear-orange-theme' = 'clear-orange-theme',
  'clear-yellow-theme' = 'clear-yellow-theme',
  'clear-blue-theme' = 'clear-blue-theme',
  'clear-green-theme' = 'clear-green-theme',
  'clear-indigo-theme' = 'clear-indigo-theme'
}

export class CuentaModel {

  id: number;
  nombre: string;
  loginResponsableId: number;
  plan: PlanModel;
  logoBytes: string;
  features: Feature[];
  theme: AvailableThemes;
  active: boolean;

}
