import { Directive, ElementRef, Input, OnInit, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, take } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { MainState } from '@app/store/main.store';
import { Permiso } from '../enums/permisos.enum';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[showIfPermission]'
})
export class HasPermissionDirective implements OnInit {

  @Input('showIfPermission') permiso: Permiso;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private store: Store) { }

  ngOnInit(): void {
    this.store
      .select(MainState.hasPermiso)
      .pipe(
        take(1),
        map((filterFn) => filterFn(this.permiso))
      )
      .subscribe((value) => {
        if (this.permiso && value) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }

}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[showIfAllPermission]'
})
export class HasAllPermissionDirective implements OnInit {

  @Input('showIfAllPermission') permisos: Permiso[];

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private store: Store) { }

  ngOnInit(): void {
    this.store
      .select(MainState.hasAllPermisos)
      .pipe(
        take(1),
        map((filterFn) => filterFn(this.permisos))
      )
      .subscribe((value) => {
        if (this.permisos && value) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }

}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[showIfAnyPermission]'
})
export class HasAnyPermissionDirective implements OnInit {

  @Input('showIfAnyPermission') permisos: Permiso[];

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private store: Store) { }

  ngOnInit(): void {
    this.store
      .select(MainState.hasAnyPermisos)
      .pipe(
        take(1),
        map((filterFn) => filterFn(this.permisos))
      )
      .subscribe((value) => {
        if (value) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      });
  }

}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[disableIfNotPermission]'
})
export class DisableIfNotPermissionDirective implements OnInit {

  @Input('disableIfNotPermission') permiso: Permiso;

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private store: Store, private transloco: TranslocoService) { }

  ngOnInit(): void {
    this.store
      .select(MainState.hasPermiso)
      .pipe(
        take(1),
        map((filterFn) => filterFn(this.permiso))
      )
      .subscribe((value) => {
        if (!this.permiso || !value) {
          this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', 'true');
          this.renderer.setAttribute(this.elementRef.nativeElement, 'title', this.transloco.translate('misc.forbidden-descripcion'));
        }
      });
  }

}
