import {environment} from '@env/environment';
import {Injectable} from '@angular/core';
import {AbstractPaginatedService} from '@app/shared/interfaces/service-classes';
import {AreaOrganizativaModel} from '@shared/models/area-organizativa.model';

@Injectable()
export class AreaOrganizativaUserService extends AbstractPaginatedService<AreaOrganizativaModel, undefined> {

  buildUrlBase(): string {
    return `${environment.api.cuenta.areasOrganizativas.user}`;
  }

}
