import {Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AzConfirmationModalService, AzModalService} from '@azigrene/components';
import {DataColumnDeclaration} from '@azigrene/data-manager';
import {DataPaginatedSearchRequest, PaginatedResponse} from '@azigrene/searchrequest';
import {TranslocoService} from '@ngneat/transloco';
import {Store} from '@ngxs/store';
import {Observable, of} from 'rxjs';
import {take} from 'rxjs/operators';
import {dynamicTableCreatorColumns} from './dynamic-field-manager.columns';
import {DynamicFieldCreatorComponent} from './modals/dynamic-field-creator/dynamic-field-creator.component';
import {DynamicTableFieldModel} from './models/dynamic-table-property.model';
import {jsonClone} from '@shared/utils';

@Component({
  selector: 'app-dynamic-field-manager',
  templateUrl: './dynamic-field-manager.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicFieldManagerComponent),
      multi: true
    }
  ]
})
export class DynamicFieldManagerComponent implements ControlValueAccessor {

  request: DataPaginatedSearchRequest<DynamicTableFieldModel>;
  columns: DataColumnDeclaration[] = jsonClone(dynamicTableCreatorColumns);
  fields: DynamicTableFieldModel[];

  private onChange: any = () => {};
  private onTouched: any = () => {};

  constructor(private store: Store, private modalService: AzModalService, private confirmationService: AzConfirmationModalService, private translateService: TranslocoService) {
    this.setTableFields([]);
  }

  writeValue(properties: DynamicTableFieldModel[]): void {
    this.setTableFields(properties);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private setTableFields(properties: DynamicTableFieldModel[]) {
    this.fields = properties ?? [];
    this.request = new DataPaginatedSearchRequest(() => this.getTableRequest(this.fields));
    this.request.load();
    this.onTouched();
    this.onChange(this.fields);
  }

  private getTableRequest(fields: DynamicTableFieldModel[]): Observable<PaginatedResponse<DynamicTableFieldModel>> {
    const response = new PaginatedResponse<DynamicTableFieldModel>();

    response.count = fields.length;
    response.data = fields;
    response.nextPage = 1;
    response.page = 1;
    response.pageSize = fields.length;
    response.previousPage = 1;
    response.totalPages = 1;

    return of(response);
  }

  create(field: DynamicTableFieldModel): void {
    const ref = this.modalService.open(DynamicFieldCreatorComponent, {
      header: field?.nombre,
      data: {
        field,
        currentFields: this.fields
      },
      styleClass: 'w-[33%]'
    });

    ref.onClose.pipe(take(1)).subscribe((newField: DynamicTableFieldModel) => {
      if (newField) {
        if (field) {
          const index = this.fields.findIndex((item) => item.nombre === field.nombre);

          if (index !== -1) {
            this.fields[index] = newField;
          }
        } else {
          this.fields.push(newField);
        }

        this.setTableFields(this.fields);
      }
    });
  }

  remove(field: DynamicTableFieldModel): void {
    this.confirmationService.open({
      confirmationText: this.translateService.translate('components.dynamic-table.confirmar-eliminar'),
      showCancel: true,
      accept: () => {
        this.setTableFields(this.fields.filter((item) => item.uuid !== field.uuid));
      },
      okText: this.translateService.translate('misc.eliminar'),
      cancelText: this.translateService.translate('misc.cancelar'),
      okButtonClass: 'danger'
    });
  }

}
