import {Injectable} from '@angular/core';
import {AgGridStateModel} from '@app/modules/cuenta/modules/informe-dinamico/components/ag-grid/models/ag-grid-estados.model';
import {Permiso} from '@app/shared/enums/permisos.enum';
import {MiembroModel} from '@app/shared/models/miembro.model';
import {RolModel} from '@app/shared/models/rol.model';
import {cloneObject} from '@app/shared/utils';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {AvailableThemes} from '@shared/models/cuenta.model';
import {DropdownModel} from '@shared/models/dropdown.model';
import {FuncionModel} from '@shared/models/funcion.model';
import {PlanModel} from '@shared/models/plan.model';
import {UsuarioLoginModel} from '@shared/models/usuario-login.model';

export interface AccountSettings {
  locale: string;
  currencyCode: string;
  dateFormat: string;
  nzDateFormat: string;
  nzDateTimeFormat: string;
  monthFormat: string;
  hourFormat: string;
  timezone: string;
  numberRegex: RegExp;
}

export const defaultAccountSettings: AccountSettings = {
  locale: 'es',
  dateFormat: 'DD/MM/YYYY',
  currencyCode: '€',
  nzDateFormat: 'dd/MM/yyyy',
  nzDateTimeFormat: 'dd/MM/yyyy H:mm:ss',
  monthFormat: 'MM/yyyy',
  hourFormat: 'HH:mm',
  timezone: 'Europe/Madrid',
  numberRegex: /^[0-9]+(,[0-9]*){0,1}$/
};

export class FillUsuario {
  static readonly type = '[Main] FillUsuario';

  constructor(public readonly usuario?: UsuarioLoginModel) {}
}

export class FillMiembro {
  static readonly type = '[Main] FillMiembro';

  constructor(public readonly miembro?: MiembroModel) {}
}

export class FillRoles {
  static readonly type = '[Main] FillRoles';

  constructor(public readonly roles?: RolModel[]) {}
}

export class FillMiembros {
  static readonly type = '[Main] FillMiembros';

  constructor(public readonly miembros?: MiembroModel[]) {}
}

export class FillMiembrosCuentasActivas {
  static readonly type = '[Main] FillMiembrosCuentasActivas';

  constructor(public readonly miembrosCuentasActivas?: MiembroModel[]) {}
}

export class AddMiembroFrecuente {
  static readonly type = '[Main] AddMiembroFrecuente';

  constructor(public readonly miembro?: MiembroModel) {}
}

export class UpdateUserSettings {
  static readonly type = '[Main] UpdateUserSettings';

  constructor(public readonly settings?: AccountSettings) {}
}

export class UpdateInformeDinamico {
  static readonly type = '[Main] UpdateInformeDinamico';

  constructor(public readonly informeDinamico?: AgGridStateModel) {}
}

export class FillPlanes {
  static readonly type = '[Main] FillPlanes';

  constructor(public readonly planes?: PlanModel[]) {}
}

export class FillFunciones {
  static readonly type = '[Main] FillFunciones]';

  constructor(public readonly funciones?: FuncionModel[]) {}
}

export interface MainStateModel {
  usuario: UsuarioLoginModel;
  preferenciasUsuario: {
    [id: string]: PreferenciasUsuario;
  };
  roles: RolModel[];
  miembros: MiembroModel[];
  miembrosCuentasActivas: MiembroModel[];
  planes: PlanModel[];
  funciones: FuncionModel[];
}

export type PreferenciasUsuario = {
  settings: AccountSettings;
  miembro: MiembroModel;
  miembrosFrecuentes: MiembroModel[];
};

@Injectable()
@State<MainStateModel>({
  name: 'main',
  defaults: {
    usuario: null,
    roles: [],
    preferenciasUsuario: {},
    miembros: [],
    miembrosCuentasActivas: [],
    planes: [],
    funciones: []
  }
})
export class MainState {
  @Selector()
  static accountSettings(state: MainStateModel): AccountSettings {
    return state.preferenciasUsuario[state.usuario.loginId].settings || defaultAccountSettings;
  }

  @Selector()
  static miembrosCuentasActivas(state: MainStateModel): MiembroModel[] {
    return state.miembrosCuentasActivas;
  }

  @Selector()
  static theme(state: MainStateModel): AvailableThemes {
    return state.preferenciasUsuario[state.usuario.loginId] ? state.preferenciasUsuario[state.usuario.loginId].miembro.cuenta.theme : AvailableThemes['default-theme'];
  }

  @Selector()
  static miembro(state: MainStateModel): MiembroModel {
    return state.preferenciasUsuario[state.usuario.loginId].miembro;
  }

  @Selector()
  static hasPermiso(state: MainStateModel): (permiso: Permiso) => boolean {
    return (permiso: Permiso) => {
      return state.preferenciasUsuario[state.usuario.loginId].miembro.rol.id === 1 || state.preferenciasUsuario[state.usuario.loginId].miembro.permisos.indexOf(permiso) >= 0;
    };
  }

  @Selector()
  static hasAllPermisos(state: MainStateModel): (permisos: Permiso[]) => boolean {
    return (permisos: Permiso[]) => {
      return state.preferenciasUsuario[state.usuario.loginId].miembro.rol.id === 1 || permisos.every((permiso) => state.preferenciasUsuario[state.usuario.loginId].miembro.permisos.includes(permiso));
    };
  }

  @Selector()
  static hasAnyPermisos(state: MainStateModel): (permisos: Permiso[]) => boolean {
    return (permisos: Permiso[]) => {
      return state.preferenciasUsuario[state.usuario.loginId].miembro.rol.id === 1 || state.preferenciasUsuario[state.usuario.loginId].miembro.permisos.filter((p: Permiso) => permisos.indexOf(p) >= 0).length > 0;
    };
  }

  @Selector()
  static hasPermisos(state: MainStateModel): (permisos: Permiso[]) => boolean {
    return (permisos: Permiso[]) => {
      return state.preferenciasUsuario[state.usuario.loginId].miembro.rol.id === 1 || state.preferenciasUsuario[state.usuario.loginId].miembro.permisos.filter((p: Permiso) => permisos.indexOf(p) >= 0).length === permisos.length;
    };
  }

  @Selector()
  static roles(state: MainStateModel): {obj: RolModel; label: string; value: number}[] {
    return state.roles.map((rol: RolModel) => {
      return {
        label: rol.codigo,
        value: rol.id,
        obj: rol
      };
    });
  }

  @Action(FillUsuario)
  fillUsuario(ctx: StateContext<MainStateModel>, action: FillUsuario): void {
    ctx.patchState({
      usuario: action.usuario
    });
  }

  @Action(FillMiembro)
  fillMiembro(ctx: StateContext<MainStateModel>, action: FillMiembro): void {
    const preferenciasUsuarioMod = cloneObject(ctx.getState().preferenciasUsuario);
    const loginId = ctx.getState().usuario.loginId;

    if (preferenciasUsuarioMod[loginId]) {
      preferenciasUsuarioMod[loginId].miembro = action.miembro;
    } else {
      preferenciasUsuarioMod[loginId] = {
        miembro: action.miembro,
        miembrosFrecuentes: null,
        settings: defaultAccountSettings
      };
    }

    ctx.patchState({
      preferenciasUsuario: preferenciasUsuarioMod
    });
    ctx.dispatch(new AddMiembroFrecuente(action.miembro));
  }

  @Action(FillRoles)
  fillRoles(ctx: StateContext<MainStateModel>, action: FillRoles): void {
    ctx.patchState({
      roles: action.roles
    });
  }

  @Action(FillMiembros)
  fillMiembros(ctx: StateContext<MainStateModel>, action: FillMiembros): void {
    ctx.patchState({
      miembros: action.miembros
    });
  }

  @Action(FillMiembrosCuentasActivas)
  fillMiembrosCuentasActivas(ctx: StateContext<MainStateModel>, action: FillMiembrosCuentasActivas): void {
    ctx.patchState({
      miembrosCuentasActivas: action.miembrosCuentasActivas
    });
  }

  @Action(FillPlanes)
  fillPlanes(ctx: StateContext<MainStateModel>, action: FillPlanes): void {
    ctx.patchState({
      planes: action.planes
    });
  }

  @Action(FillFunciones)
  fillFunciones(ctx: StateContext<MainStateModel>, action: FillFunciones): void {
    ctx.patchState({
      funciones: action.funciones
    });
  }

  @Action(AddMiembroFrecuente)
  addMiembroFrecuente(ctx: StateContext<MainStateModel>, action: AddMiembroFrecuente): void {
    let found = false;
    const preferenciasUsuario = cloneObject(ctx.getState().preferenciasUsuario);
    const loginId = ctx.getState().usuario.loginId;

    if (preferenciasUsuario[loginId].miembrosFrecuentes) {
      for (const miembroFrecuente of preferenciasUsuario[loginId].miembrosFrecuentes) {
        if (miembroFrecuente.id === action.miembro.id) {
          found = true;
          // actualizamos nombre en caso de actualizacion de nombre de cuenta
          miembroFrecuente.cuenta.nombre = action.miembro.cuenta.nombre;
        }
      }
    }

    if (!found) {
      if (preferenciasUsuario[loginId] && preferenciasUsuario[loginId].miembrosFrecuentes) {
        preferenciasUsuario[loginId].miembrosFrecuentes.push(action.miembro);
      } else {
        preferenciasUsuario[loginId] = {
          miembro: action.miembro,
          miembrosFrecuentes: [action.miembro],
          settings: defaultAccountSettings
        };
      }
    }
  }

  @Action(UpdateUserSettings)
  updateUserSettings(ctx: StateContext<MainStateModel>, action: UpdateUserSettings): void {
    const preferenciasUsuario = cloneObject(ctx.getState().preferenciasUsuario);
    const loginId = ctx.getState().usuario.loginId;

    preferenciasUsuario[loginId].settings = {
      ...preferenciasUsuario.settings,
      ...action.settings
    };
    ctx.patchState({preferenciasUsuario});
  }

  @Action(UpdateInformeDinamico)
  updateInformeDinamico(ctx: StateContext<MainStateModel>, action: UpdateInformeDinamico): void {
    const preferenciasUsuario = cloneObject(ctx.getState().preferenciasUsuario);
    const loginId = ctx.getState().usuario.loginId;
    const miembro: MiembroModel = preferenciasUsuario[loginId].miembro;

    if (miembro.preferencias?.informeDinamico) {
      preferenciasUsuario[loginId].miembro.preferencias.informeDinamico = {
        ...miembro.preferencias.informeDinamico,
        ...action.informeDinamico
      };
    } else {
      preferenciasUsuario[loginId].miembro.preferencias = {
        informeDinamico: action.informeDinamico
      };
    }

    ctx.patchState({preferenciasUsuario});
  }

  @Selector()
  static planes(state: MainStateModel): DropdownModel<PlanModel>[] {
    return state.planes.map((plan: PlanModel) => {
      return {
        label: plan.codigo,
        value: plan.id,
        obj: plan
      };
    });
  }

  @Selector()
  static funciones(state: MainStateModel): FuncionModel[] {
    return state.funciones.map((funcion: FuncionModel) => {
      return {
        id: funcion.id,
        nombre: funcion.nombre,
        value: false,
        readonly: false
      };
    });
  }
}
