import { EnvironmentEndpoints, IAPPConfig } from "@config/iconfig";

export const appConfig: IAPPConfig = {
  apiUrl: window['env']['apiUrl'] || 'http://localhost:8087',
  footer: false,
  ssoOnly: window['env']['ssoOnly'] || false,
  ssoConfig: window['env']['ssoConfig'] || null,
};

export const apiEndpoints: EnvironmentEndpoints = {
  user: appConfig.apiUrl + '/user',
  auth: appConfig.apiUrl + '/auth',
  recuperarPassword: appConfig.apiUrl + '/recuperar-password',
  sso: appConfig.apiUrl + '/sso',
  ssoLogout: appConfig.apiUrl + '/sso-logout',
  admin: {
    defaultFactoresEmision: {
      admin: appConfig.apiUrl + '/admin/default-factores-emision',
      user: appConfig.apiUrl + '/user/default-factores-emision'
    },
    defaultFuenteEmision: {
      admin: appConfig.apiUrl + '/admin/default-fuentes-emision',
      user: appConfig.apiUrl + '/user/default-fuentes-emision'
    },
    defaultTiposEmisor: {
      admin: appConfig.apiUrl + '/admin/default-tipos-emisor',
      user: appConfig.apiUrl + '/user/default-tipos-emisor'
    },
    invitaciones: {
      admin: appConfig.apiUrl + '/admin/cuentas/invite'
    },
    cuentas: {
      admin: appConfig.apiUrl + '/admin/cuentas'
    },
    miembros: {
      admin: appConfig.apiUrl + '/admin/miembros'
    },
    logins: {
      admin: appConfig.apiUrl + '/admin/logins'
    },
    sesiones: {
      admin: appConfig.apiUrl + '/admin/sesiones'
    },
    planes: {
      admin: appConfig.apiUrl + '/admin/planes',
      user: appConfig.apiUrl + '/user/planes'
    },
    fuentes: {
      admin: appConfig.apiUrl + '/admin/fuentes',
      user: appConfig.apiUrl + '/user/fuentes'
    },
    tiposEmision: {
      admin: appConfig.apiUrl + '/admin/tipos-emision',
      user: appConfig.apiUrl + '/user/tipos-emision'
    },
    zonas: {
      admin: appConfig.apiUrl + '/admin/zonas',
      user: appConfig.apiUrl + '/user/zonas',
      cuenta: appConfig.apiUrl + '/cuentas/{cuentaId}/zonas'
    },
    unidades: {
      admin: appConfig.apiUrl + '/admin/unidades',
      user: appConfig.apiUrl + '/user/unidades'
    },
    origenesDatoEmisor: {
      admin: appConfig.apiUrl + '/admin/origenes-dato-emisor',
      user: appConfig.apiUrl + '/user/origenes-dato-emisor'
    },
    alcances: {
      admin: appConfig.apiUrl + '/admin/alcances',
      user: appConfig.apiUrl + '/user/alcances'
    },
    metodologiasCalculo: {
      admin: appConfig.apiUrl + '/admin/metodologias-calculo',
      user: appConfig.apiUrl + '/user/metodologias-calculo'
    }
  },
  baseCore: appConfig.apiUrl + '/base/core',
  cuenta: {
    areasOrganizativas: {
      cuenta: appConfig.apiUrl + '/cuentas/{cuentaId}/agrupaciones',
      user: appConfig.apiUrl + '/cuentas/{cuentaId}/agrupaciones-user',
      admin: appConfig.apiUrl + '/admin/cuentas/{cuentaId}/agrupaciones'
    },
    cuentas: appConfig.apiUrl + '/cuentas',
    miembros: appConfig.apiUrl + '/cuentas/{cuentaId}/miembros-user',
    emisores: {
      cuenta: appConfig.apiUrl + '/cuentas/{cuentaId}/emisores-cuenta',
      user: appConfig.apiUrl + '/cuentas/{cuentaId}/emisores-user'
    },
    factorEmision: {
      user: appConfig.apiUrl + '/cuentas/{cuentaId}/factores-emision-user'
    },
    tiposEmisor: {
      user: appConfig.apiUrl + '/cuentas/{cuentaId}/tipos-emisor-user'
    },
    fuentesEmision: {
      user: appConfig.apiUrl + '/cuentas/{cuentaId}/fuentes-emision-user'
    },
    invitaciones: appConfig.apiUrl + '/cuentas',
    datoEmisor: {
      user: appConfig.apiUrl + '/cuentas/{cuentaId}/datos-emisores-user'
    },
    informeDinamico: appConfig.apiUrl + '/cuentas/{cuentaId}/informe-dinamico',
    integraciones: {
      cuenta: appConfig.apiUrl + '/cuentas/{cuentaId}/integraciones'
    },
    invitacionesCuenta: appConfig.apiUrl + '/cuentas',
    miembrosCuenta: appConfig.apiUrl + '/cuentas/{cuentaId}/miembros',
    grupoPermisos: appConfig.apiUrl + '/cuentas/{cuentaId}/grupos-permisos',
    rolesCuenta: appConfig.apiUrl + '/cuentas/{cuentaId}/roles-cuenta',
    rolesCuentaMiembro: appConfig.apiUrl + '/cuentas/{cuentaId}/roles-cuenta-miembro',
    widgets: {
      lastYearOfEmisions: appConfig.apiUrl + '/cuentas/{cuentaId}/widgets/last-year-of-emisions',
      emisionesMensuales: appConfig.apiUrl + '/cuentas/{cuentaId}/widgets/emisiones-mensuales',
      emisionesAnuales: appConfig.apiUrl + '/cuentas/{cuentaId}/widgets/emisiones-anuales',
      emisionesPorFuente: appConfig.apiUrl + '/cuentas/{cuentaId}/widgets/emisiones-por-fuente',
      emisionesPorTipoEmisor: appConfig.apiUrl + '/cuentas/{cuentaId}/widgets/emisiones-por-tipo-emisor',
      consumosPorGDO: appConfig.apiUrl + '/cuentas/{cuentaId}/widgets/consumos-por-gdo',
      emisionesAnualesPorAlcance: appConfig.apiUrl + '/cuentas/{cuentaId}/widgets/emisiones-anuales-por-alcance',
      emisionesAnualesPorZona: appConfig.apiUrl + '/cuentas/{cuentaId}/widgets/emisiones-anuales-por-zona',
      emisionesMensualesPorAlcance: appConfig.apiUrl + '/cuentas/{cuentaId}/widgets/emisiones-mensuales-por-alcance',
      emisionesMensualesPorFuente: appConfig.apiUrl + '/cuentas/{cuentaId}/widgets/emisiones-mensuales-por-fuente'
    },
    configuracionSSO: appConfig.apiUrl + '/cuentas/{cuentaId}/configuracion-sso',
    apiTokensCuenta: appConfig.apiUrl + '/cuentas/{cuentaId}/api-tokens'
  }
}

