import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {RecuperacionPasswordModel} from '@shared/models/recuperacion-password.model';

@Injectable()
export class RecuperarPasswordService {

  constructor(private http: HttpClient) {}

  recuperarPassword(recuperar: {email: string}): Observable<boolean> {
    return this.http.post<boolean>(environment.api.recuperarPassword, recuperar);
  }

  getRecuperacionPasswordByToken(token: string): Observable<RecuperacionPasswordModel> {
    return this.http.get<RecuperacionPasswordModel>(environment.api.recuperarPassword + '/' + token + '/one');
  }

  aceptarPassword(recuperar: {token: string; password: string}): Observable<boolean> {
    return this.http.post<boolean>(environment.api.recuperarPassword + '/aceptar-password', recuperar);
  }

}
