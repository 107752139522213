import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'az-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.css']
})
export class PageHeaderComponent {

  @Input() backIcon = false;
  @Input() title = '';
  @Input() subtitle = '';
  @Input() backPage = './../';
  @Input() backText: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
  }

  onBack(): void {
    this.router.navigate([this.backPage], { relativeTo: this.activatedRoute });
  }

}
