import {environment} from '@env/environment';
import {Injectable} from '@angular/core';
import {AbstractPaginatedService} from '@app/shared/interfaces/service-classes';
import {ZonaModel} from '@shared/models/zona.model';
import {Observable} from 'rxjs';

@Injectable()
export class ZonasCuentaService extends AbstractPaginatedService<ZonaModel, undefined> {
  buildUrlBase(): string {
    return `${environment.api.admin.zonas.cuenta}`;
  }

  getUsedByEmisores(): Observable<ZonaModel[]> {
    return this.http.get<ZonaModel[]>(this.buildUrlBase() + '/by-emisores');
  }
}
