import { Pipe, PipeTransform } from '@angular/core';
import { MainState } from '@app/store/main.store';
import { SidebarLink } from '@azigrene/components';
import { Select } from '@ngxs/store';
import { clone } from 'lodash';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { MiembroModel } from '../models/miembro.model';

@Pipe({
  name: 'filterLinksAccess'
})
export class FilterLinksAccessPipe implements PipeTransform {

  @Select(MainState.miembro) miembroSeleccionado$: Observable<MiembroModel>;
  private permisos: string[] = [];
  private isAdmin = false;

  constructor() {
    this.miembroSeleccionado$.pipe(take(1)).subscribe((miembro) => {
      if (miembro.usuario.rolAplicacion === 'ADMIN' || miembro.rol.codigo === 'ADMIN') {
        this.isAdmin = true;
      } else {
        this.permisos = miembro.permisos;
      }
    });
  }

  transform(links: SidebarLink[]): SidebarLink[] {
    const filteredLinks = clone(links);

    return this.filterLinks(filteredLinks);
  }

  private filterLinks(links: SidebarLink[]): SidebarLink[] {
    return links.filter((link) => {
      const hasPermission = !link.permiso || this.isAdmin ? true : !!this.permisos.find((permiso) => permiso === link.permiso);

      if (link.children?.length > 0) {
        link.children = this.filterLinks(link.children);
      }

      return hasPermission;
    });
  }

}
