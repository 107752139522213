import {Component} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {AzModalConfig, AzModalRef, ModalBaseComponent} from '@azigrene/components';

@Component({
  selector: 'app-dynamic-field-select-options-creator',
  templateUrl: './dynamic-field-select-options-creator.component.html'
})
export class DynamicFieldSelectOptionsCreatorComponent extends ModalBaseComponent {

  form: FormGroup;

  constructor(public config: AzModalConfig, public ref: AzModalRef, private formBuilder: FormBuilder) {
    super(config, ref);
    this.form = formBuilder.group({
      label: [null, [Validators.required, this.uniqueNameValidator()]]
    });
    if (this.config.data) {
      const {option} = this.config.data;

      this.form.patchValue({label: option});
    }
  }

  uniqueNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const {options} = this.config.data;
      const names: string[] = options ?? [];

      return names?.includes(control.value) ? {'unique-name': true} : null;
    };
  }

}
