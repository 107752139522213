import {take} from 'rxjs/operators';
import {TranslocoService} from '@ngneat/transloco';
import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {AzModalConfig, AzModalRef, AzNotifierService} from '@azigrene/components';
import {Select} from '@ngxs/store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ISteps, StepperComponent} from '@shared/components/stepper/stepper.component';
import {saveAs} from 'file-saver';
import {MainState} from '@store/main.store';

export interface ImportResult {
  fila: number;
  operacion: string;
  errores: any[];
  avisos: any[];
  campoId: string;
  campo: string;
  mensaje: string;
}

@Component({
  selector: 'app-dialogo-importacion',
  templateUrl: './dialogo-importacion.component.html',
  styleUrls: ['../dialogo-importacion-creacion/dialogo-importacion-creacion.component.scss']
})
export class DialogoImportacionComponent implements OnInit, AfterViewInit, OnDestroy {

  @Select(MainState.theme) theme$;
  steps: ISteps = {
    tipoFichero: {
      title: this.translateService.translate('components.dialogo-importar.seleccionar-fichero'),
      canGoBack: true,
      disabled: false,
      previous: null,
      active: true,
      next: 2,
      nextStepText: this.translateService.translate('misc.importar'),
      nextEnabled: (() => this.isFileUploaded).bind(this),
      previousEnabled: (): boolean => true
    },
    resultados: {
      title: this.translateService.translate('common.resultados'),
      canGoBack: false,
      disabled: false,
      previous: 1,
      next: null
    }
  };

  urlBase: string;
  exitPressed = false;
  resultado: any;
  errores: ImportResult[];
  avisos: ImportResult[];
  errorFileProcess: string;
  showErroresAvisos: boolean;
  fileDownload: any;
  hasErrors: boolean;
  cols: any[];
  fileName = this.translateService.translate('components.dialogo-importar.archivo-no-seleccionado');
  isFileUploaded = false;
  loading = false;
  subscription$: (file, formato?) => Observable<any>;
  @ViewChild('file', {static: true}) file;
  @ViewChild('stepper', {static: true}) stepper: StepperComponent;
  @ViewChild('dropContainer', {static: true}) dropContainer;

  formTipoImportacion: FormGroup;
  rowsUpdatedEvent: EventEmitter<boolean>;
  subscriptions$: Subscription = new Subscription();

  ngOnInit(): void {
    this.subscription$ = this.config.data.subscription;
    this.rowsUpdatedEvent = this.config.data?.rowsUpdatedEvent;
    this.urlBase = this.config.data.urlBase;
    this.cols = [
      {field: 'fila', header: this.translateService.translate('common.fila')},
      {field: 'operacion', header: this.translateService.translate('common.operacion')},
      {field: 'errores', header: this.translateService.translate('common.errores')},
      {field: 'avisos', header: this.translateService.translate('common.avisos')}
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions$?.unsubscribe();
  }

  constructor(public config: AzModalConfig, public ref: AzModalRef, private translateService: TranslocoService, private notifierService: AzNotifierService, private formBuilder: FormBuilder) {
    this.formTipoImportacion = formBuilder.group({
      tipoImportacion: ['file', Validators.required]
    });

    const formTipoImportacion = this.formTipoImportacion.valueChanges.subscribe(() => {
      if (this.stepper.activeStep === 'seleccion') {
        this.stepper.nextEnabled = this.formTipoImportacion.valid;
      }
    });

    this.subscriptions$.add(formTipoImportacion);
  }

  ngAfterViewInit(): void {
    if (this.dropContainer) {
      this.dropContainer.nativeElement.ondragover = (evt) => {
        evt.preventDefault();
      };

      this.dropContainer.ondrop = (evt) => {
        this.file.files = evt.dataTransfer.files;
        evt.preventDefault();
      };
    }
  }

  show(event): void {
    this.exitPressed = false;
  }

  hide(event): void {
    this.exitPressed = true;
  }

  setFile(file): void {
    if (file) {
      this.file = file;
      this.fileName = file.name.replace(/.*[\/\\]/, '');
      if (file.value !== '') {
        this.isFileUploaded = true;
        this.stepper.nextEnabled = true;
      } else {
        this.isFileUploaded = false;
      }
    }
  }

  import(): void {
    if (this.file) {
      this.loading = true;
      const nombreFichero = this.file.name;
      const formato: string = nombreFichero.substring(nombreFichero.lastIndexOf('.') + 1, nombreFichero.length).toUpperCase();

      this.subscription$(this.file, formato)
        .pipe(take(1))
        .subscribe(
          (results: any) => {
            this.resultado = results;
            this.fileDownload = results.fileDownload;
            if (results.errorFileProcess != null) {
              this.showErroresAvisos = false;
              this.errorFileProcess = results.errorFileProcess;
            } else {
              this.showErroresAvisos = true;
              this.errores = this.resultsToImportResults(results, (value) => value.operacion === 'ERROR');
              this.avisos = this.resultsToImportResults(results, (value) => value.avisos.length > 0);
            }

            this.hasErrors = (this.errorFileProcess != null || this.errores?.length || this.avisos?.length) && (formato.toUpperCase() === 'XLS' || formato.toUpperCase() === 'XLSX');
            this.loading = false;
            this.stepper.nextEnabled = true;
            this.rowsUpdatedEvent?.emit((this.resultado?.insertados || 0) + (this.resultado?.actualizados || 0) > 0);
          },
          (error) => {
            this.notifierService.showBackendError(error.error);
            this.loading = false;
          }
        );
    }
  }

  resultsToImportResults(results: any, filterFunction: (value) => boolean): ImportResult[] {
    return results.resultado
      .filter((value) => filterFunction(value))
      .map((row) => ({
        fila: row.fila,
        operacion: row.operacion,
        errores: row.errores,
        avisos: row.avisos,
        campo: row.campo,
        mensaje: row.mensaje
      }));
  }

  pasterImport(data: any[]): void {
    this.loading = true;
    this.subscription$(data, 'JSON')
      .pipe(take(1))
      .subscribe(
        (results: any) => {
          this.resultado = results;
          this.errores = results.resultado
            .filter((value) => value.operacion === 'ERROR')
            .map((row) => {
              return {
                data: {
                  fila: row.fila,
                  operacion: row.operacion,
                  errores: row.errores ? row.errores.length : 0,
                  avisos: row.avisos ? row.avisos.length : 0
                },
                children: (row.errores
                  ? row.errores.map((error) => {
                      return {
                        data: {
                          fila: error.campo,
                          operacion: error.mensaje,
                          errores: '',
                          avisos: this.translateService.translate('common.error')
                        }
                      };
                    })
                  : []
                ).concat(
                  row.avisos
                    ? row.avisos.map((error) => {
                        return {
                          data: {
                            fila: error.campo,
                            operacion: error.mensaje,
                            errores: '',
                            avisos: this.translateService.translate('common.aviso')
                          }
                        };
                      })
                    : []
                )
              };
            });
          this.loading = false;
        },
        () => {
          this.notifierService.showInfoError(this.translateService.translate('components.dialogo-importar.error-descripcion'), this.translateService.translate('common.error'));
          this.loading = false;
        }
      );
  }

  onNextStep(step: {from: string; to: string}): void {
    switch (step.from) {
      case 'seleccion':
        if (this.formTipoImportacion.controls.tipoImportacion.value === 'file') {
          this.stepper.activeStep = 'tipoFichero';
          this.stepper.hideStep('tipoPaster');
          this.stepper.nextEnabled = false;
        } else if (this.formTipoImportacion.controls.tipoImportacion.value === 'paster') {
          this.stepper.activeStep = 'tipoPaster';
          this.stepper.hideStep('tipoFichero');
          this.stepper.nextEnabled = false;
        }

        break;
      case 'tipoFichero':
        if (this.file) {
          this.import();
          this.stepper.activeStep = 'resultados';
          this.stepper.nextEnabled = false;
        }

        break;
    }

    this.stepper.previousEnabled = false;
  }

  onPrevious(step: {from: string; to: string}): void {
    switch (step.from) {
      case 'tipoFichero':
      case 'tipoPaster':
        this.formTipoImportacion.reset();
        this.stepper.activeStep = 'seleccion';
        break;
    }
  }

  downloadFile(): void {
    const binaryString = window.atob(this.fileDownload);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    saveAs(new Blob([bytes], {type: 'application/octet-stream'}), this.translateService.translate('common.resultados') + '_' + this.fileName);
  }

}
