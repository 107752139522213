import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {FormUtils} from '@shared/utils';
import {take} from 'rxjs/operators';
import {InvitacionModel} from '@shared/models/invitacion.model';
import {InvitacionesService} from '@shared/services/invitaciones.service';

@Component({
  selector: 'app-nuevo-login',
  templateUrl: './nuevo-login.component.html'
})
export class NuevoLoginComponent {

  nuevoLoginForm: FormGroup;
  sent = false;
  valid = true;
  invitacion: InvitacionModel;
  errors: string[] = [];

  constructor(public router: Router, public store: Store, private activatedRoute: ActivatedRoute, private invitacionesService: InvitacionesService, private formBuilder: FormBuilder, private translateService: TranslocoService) {
    const token = this.activatedRoute.snapshot.params.token;

    this.nuevoLoginForm = this.formBuilder.group({
      nombre: [null, {validators: [Validators.required], updateOn: 'change'}],
      apellidos: [null, {validators: [Validators.required], updateOn: 'change'}],
      password: [null, {validators: [Validators.required, FormUtils.validators.checkPassword], updateOn: 'change'}],
      repetirPassword: [null, {validators: [Validators.required, FormUtils.validators.checkPassword], updateOn: 'change'}]
    });
    this.invitacionesService
      .getByToken(token)
      .pipe(take(1))
      .subscribe((invitacion: InvitacionModel) => {
        if (!invitacion || !invitacion.activa) {
          this.valid = false;
        }

        this.invitacion = invitacion;
      });
  }

  save(): void {
    this.errors = [];
    if (this.nuevoLoginForm.get('password').value !== this.nuevoLoginForm.get('repetirPassword').value) {
      this.errors.push(this.translateService.translate('auth.nuevo-login.passwords-no-coinciden'));

      return;
    }

    const token = this.activatedRoute.snapshot.params.token;

    this.invitacionesService
      .acceptInvite(token, this.nuevoLoginForm.value)
      .pipe(take(1))
      .subscribe(
        () => {
          this.sent = true;
        },
        (error: any) => {
          if (error.error.subErrors) {
            for (error of error.error.subErrors) {
              this.errors.push(error.message);
            }
          } else {
            this.errors.push(error.error.message);
          }
        }
      );
  }

}
