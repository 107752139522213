import {environment} from '@env/environment';
import {Injectable} from '@angular/core';
import {AbstractNoPaginatedService} from '@app/shared/interfaces/service-classes';
import {EnumModel} from '@shared/models/enum.model';

@Injectable()
export class TiposEmisionUserService extends AbstractNoPaginatedService<EnumModel, undefined> {

  buildUrlBase(): string {
    return `${environment.api.admin.tiposEmision.user}`;
  }

}
