import {Injectable} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthState} from '@app/store/auth.store';
import {FillMiembro, FillMiembros, FillMiembrosCuentasActivas, MainState} from '@app/store/main.store';
import {Store} from '@ngxs/store';
import {take} from 'rxjs/operators';
import {MiembroModel} from '../models/miembro.model';
import {getRedirectData} from '../utils';
import {MiembrosService} from './miembros.service';

@Injectable()
export class LoginRoutingService {

  constructor(private store: Store, private miembrosService: MiembrosService, private router: Router, private activatedRoute: ActivatedRoute) {}

  enrouteUser(isBaseURL?: boolean): void {
    const params = this.activatedRoute.snapshot.queryParams;

    if (params.redirect_url) {
      const url = getRedirectData(params.redirect_url + '?' + params.redirect_params);

      this.router.navigate([url.redirect_url], {
        queryParams: url.redirect_params
      });
    }

    const miembroSeleccionado = this.store.selectSnapshot(MainState.miembro);

    this.miembrosService
      .getAll()
      .pipe(take(1))
      .subscribe((miembrosUsuario: MiembroModel[]) => {
        this.store.dispatch(new FillMiembros(miembrosUsuario));
        this.store.dispatch(new FillMiembrosCuentasActivas(miembrosUsuario.filter((miembro) => miembro?.cuenta?.active)));
        this.redirectMiembro(miembroSeleccionado, miembrosUsuario, params, isBaseURL);
      });
  }

  private redirectMiembro(miembroSeleccionado, miembrosUsuario: MiembroModel[], params: Params, isBaseURL?: boolean) {
    const isBaseUrl = isBaseURL ? isBaseURL : this.router.url === '/';

    if (!miembroSeleccionado && !miembrosUsuario.length && this.store.selectSnapshot(AuthState.isAdminUser)) {
      this.router.navigate(['/admin']);
    } else if (!miembroSeleccionado && miembrosUsuario.length) {
      if (isBaseUrl) {
        this.router.navigate(['/seleccionar-cuenta']);

        return;
      }

      const myRegexp = /\/cuenta\/(\d+)\/\w+/g;
      const match = myRegexp.exec(params.redirect_url);

      if (match && match.length > 1) {
        const cuentaId = Number(match[1]);
        const miembroRuta = miembrosUsuario.find((miembroUsuario: MiembroModel) => (miembroUsuario.cuenta.id = cuentaId));

        this.store.dispatch(new FillMiembro(miembroRuta));
      } else {
        this.store.dispatch(new FillMiembro(miembrosUsuario[0]));
      }
    } else if (miembroSeleccionado && miembrosUsuario.length && isBaseUrl) {
      this.redirectWithMiembroSeleccionado(miembroSeleccionado, miembrosUsuario);
    }
  }

  private redirectWithMiembroSeleccionado(miembroSeleccionado, miembrosUsuario: MiembroModel[]) {
    const miembroHasCuentaSeleccionada = miembrosUsuario.find((miembro) => miembro.cuenta.id === miembroSeleccionado.cuenta.id);

    if (miembroHasCuentaSeleccionada) {
      this.router.navigate([`cuenta/${miembroSeleccionado.cuenta.id}`]);
    } else {
      this.router.navigate(['/seleccionar-cuenta']);
    }
  }

}
