export const RolAplicacion  = {
  ADMIN: {
    id: 1,
    codigo: 'ADMIN'
  },
  USER: {
    id: 2,
    codigo: 'USER'
  }
};
