import {InvitacionModel} from '../models/invitacion.model';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import { MainState } from '@app/store/main.store';
import { Store } from '@ngxs/store';

@Injectable()
export class InvitacionesService {

  urlBase = environment.api.cuenta.invitaciones;

  constructor(private http: HttpClient, private store: Store) {}

  acceptInvite(token: string, invitacion: InvitacionModel): Observable<InvitacionModel> {
    return this.http.put<InvitacionModel>(this.urlBase + '/accept-invite/' + token, invitacion);
  }

  getByToken(token: string): Observable<InvitacionModel> {
    return this.http.get<InvitacionModel>(this.urlBase + '/invitaciones/token/' + token);
  }

  invite(invitacion: InvitacionModel): Observable<any> {
    const miembro = this.store.selectSnapshot(MainState.miembro);
    
    invitacion.cuentaId = miembro.cuenta.id;

    return this.http.post(this.urlBase + '/invite', invitacion);
  }

  resendInvitations(invitationIds: number[]): Observable<any> {
    return this.http.post(this.urlBase + '/resend-invitations/', invitationIds);
  }

}
