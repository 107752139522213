import {AgGridStateModel} from '@app/modules/cuenta/modules/informe-dinamico/components/ag-grid/models/ag-grid-estados.model';
import {CuentaModel} from './cuenta.model';
import {GrupoPermisosModel} from './grupo-permisos.model';
import {InvitacionModel} from './invitacion.model';
import {RolModel} from './rol.model';
import {UsuarioModel} from './usuario.model';

export class MiembroModel {
  id: number;
  usuarioId: number;
  usuario: UsuarioModel;
  cuentaId: number;
  cuenta: CuentaModel;
  rolId: number;
  rol: RolModel;
  grupoPermisosId: number;
  grupoPermisos: GrupoPermisosModel;
  invitacionId: number;
  invitacion: InvitacionModel;
  nombre: string;
  puesto: string;
  permisos: string[];
  createdAt: Date;
  updatedAt: Date;
  preferencias: PreferenciasMiembroModel;
}

export interface PreferenciasMiembroModel {
  informeDinamico: AgGridStateModel;
}
