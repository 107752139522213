import {environment} from "@env/environment";
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CuentaModel} from '@shared/models/cuenta.model';

@Injectable()
export class CuentasService {

  constructor(private http: HttpClient) {}

  urlBase = environment.api.cuenta.cuentas;

  get(cuentaId: number): Observable<CuentaModel> {
    return this.http.get<CuentaModel>(this.urlBase + '/' + cuentaId + '/one');
  }

  update(cuentaForm: CuentaModel): Observable<CuentaModel> {
    return this.http.put<CuentaModel>(this.urlBase + '/' + cuentaForm.id + '/update', cuentaForm);
  }

}
