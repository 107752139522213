import {environment} from '@env/environment';
import {Injectable} from '@angular/core';
import {AbstractBackendAccionMasivaService} from '@app/shared/interfaces/service-classes';
import {EmisorModel} from '@shared/models/emisor.model';
import {Observable} from 'rxjs';
import {ResultadoValidacionModel} from '@shared/models/resultado-validacion.model';
import {SearchRequest} from '@azigrene/searchrequest';
import {HttpResponse} from '@angular/common/http';

@Injectable()
export class EmisoresUserService extends AbstractBackendAccionMasivaService<EmisorModel, undefined, unknown> {

  buildUrlBase(): string {
    return `${environment.api.cuenta.emisores.user}`;
  }

  importDynamicFields(tipoEmisorId: number, file: unknown): Observable<ResultadoValidacionModel> {
    return this.getHttp().post<ResultadoValidacionModel>(this.buildUrlBase() + `/tipos-emisor/${tipoEmisorId}/import/`, file);
  }

  exportDynamicFields(tipoEmisorId: number, searchRequest: SearchRequest, formato?: string): Observable<HttpResponse<Blob>> {
    if (formato) {
      return this.getHttp().post(this.buildUrlBase() + `/tipos-emisor/${tipoEmisorId}/export/` + formato, searchRequest, {
        observe: 'response',
        responseType: 'blob'
      });
    } else {
      return this.getHttp().post(this.buildUrlBase() + `/tipos-emisor/${tipoEmisorId}/export/`, searchRequest, {
        observe: 'response',
        responseType: 'blob'
      });
    }
  }

}
