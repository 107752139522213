import {RecuperarPasswordService} from '@shared/services/recuperar-password.service';
import {RecuperacionPasswordModel} from '@shared/models/recuperacion-password.model';
import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {take} from 'rxjs/operators';
import {FormUtils} from '@shared/utils';

@Component({
  selector: 'app-nuevo-password',
  templateUrl: './nuevo-password.component.html'
})
export class NuevoPasswordComponent {

  nuevoPasswordForm: FormGroup;
  isSent = false;
  isTokenValid = true;
  recuperacionPassword: RecuperacionPasswordModel;
  errors: string[] = [];

  constructor(public router: Router, public store: Store, private activatedRoute: ActivatedRoute, private recuperarPasswordService: RecuperarPasswordService, private formBuilder: FormBuilder, private translateService: TranslocoService) {
    const token = this.activatedRoute.snapshot.params.token;

    this.nuevoPasswordForm = this.formBuilder.group({
      password: [null, {validators: [Validators.required, FormUtils.validators.checkPassword], updateOn: 'change'}],
      repetirPassword: [null, {validators: [Validators.required, FormUtils.validators.checkPassword], updateOn: 'change'}]
    });
    this.recuperarPasswordService
      .getRecuperacionPasswordByToken(token)
      .pipe(take(1))
      .subscribe(
        (recuperacionPassword: RecuperacionPasswordModel) => {
          if (!recuperacionPassword || !recuperacionPassword.activo) {
            this.isTokenValid = false;
          }

          this.recuperacionPassword = recuperacionPassword;
        },
        () => {
          this.isTokenValid = false;
        }
      );
  }

  save(): void {
    this.errors = [];
    if (this.nuevoPasswordForm.get('password').value !== this.nuevoPasswordForm.get('repetirPassword').value) {
      this.errors.push(this.translateService.translate('auth.nuevo-login.passwords-no-coinciden'));

      return;
    }

    const tokenParam = this.activatedRoute.snapshot.params.token;

    this.recuperarPasswordService
      .aceptarPassword({token: tokenParam, password: this.nuevoPasswordForm.get('password').value})
      .pipe(take(1))
      .subscribe(
        (result: boolean) => {
          if (result) {
            this.isSent = true;
          }
        },
        (error: any) => {
          if (error.error.subErrors) {
            for (error of error.error.subErrors) {
              this.errors.push(error.message);
            }
          } else {
            this.errors.push(error.error.message);
          }
        }
      );
  }

}
