import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { LoginResponse, LoginSSOResponse, LogoutSSOResponse } from "./../models/auth.model";
import { environment } from "@env/environment";

@Injectable()
export class AuthService {

  constructor(private http: HttpClient) {}

  auth(credentials: {email: string; password: string}): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(environment.api.auth, credentials);
  }

  sso(auth: {email: string}): Observable<LoginSSOResponse> {
    return this.http.post<LoginSSOResponse>(environment.api.sso, auth);
  }
  ssoConfig(configId: number): Observable<LoginSSOResponse> {
    return this.http.get<LoginSSOResponse>(`${environment.api.sso}/${configId}`);
  }

  ssoLogout(email: string): Observable<LogoutSSOResponse> {
    return this.http.post<LogoutSSOResponse>(environment.api.ssoLogout, {email});
  }

}
