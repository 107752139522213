import {Component, HostBinding, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AzModalConfig, AzModalRef, AzModalService, AzOverlayComponent} from '@azigrene/components';
import {TranslocoService} from '@ngneat/transloco';
import {DataColumnDeclaration, DataFilterDeclaration, DataListComponent, DataSelection} from '@azigrene/data-manager';
import {Observable, Subscription} from 'rxjs';
import {DataPaginatedSearchRequest, PaginatedResponse, SearchRequest} from '@azigrene/searchrequest';
import {SearchConfig, SearchSelection} from '@shared/directives/search-component.directive';
import {searchRequestFromJSON} from '@shared/utils';
import {take} from 'rxjs/operators';
import {get} from 'lodash';
import {CsvCopyEmisoresComponent} from '@shared/dialogs/csv-copy-emisores/csv-copy-emisores.component';

@Component({
  templateUrl: '../../templates/seleccion-multiple/seleccion-multiple.component.html'
})
export class SeleccionMultipleComponent<T extends {id: number}> implements OnInit, OnDestroy {

  @HostBinding('class') class = 'app-scroll-page h-full max-h-screen modal';
  @Input() globalFilter = false;
  @Input() isAdmin = false;
  @Input() dropdownTemplate: AzOverlayComponent;
  @Input() currentSelection: T[] = [];
  @ViewChild('filterList') filterList: DataListComponent;

  columns: DataColumnDeclaration[] = [
    {field: 'codigo', name: 'puntos-suministro.codigo', alwaysVisible: true},
    {
      field: 'nombre',
      name: 'common.nombre',
      alwaysVisible: true
    }
  ];

  observable: (sr) => Observable<PaginatedResponse<any>>;
  itemsRequest: DataPaginatedSearchRequest<T>;

  filterDeclarations: {[id: string]: DataFilterDeclaration} = {};

  temporalSelection: {
    type: 'SearchRequest' | 'Items';
    label?: string;
    data?: T[];
    searchRequest?: SearchRequest;
    response?: PaginatedResponse<T>;
    count: number;
  } = null;

  listSelection: DataSelection;
  searchSelection: SearchSelection<T>;
  filteredSelection: T[] = null;
  searchTerm = '';
  subscriptionSR: Subscription;
  readonly NEXT_PAGE_ACTION = 10;
  readonly PREVIOUS_PAGE_ACTION = 11;

  readonly configData: SearchConfig<T>;

  constructor(private transloco: TranslocoService, public ref: AzModalRef, public config: AzModalConfig, private dialogService: AzModalService) {
    this.configData = config.data;

    if (this.configData) {
      if (this.configData.observable) {
        this.observable = this.configData.observable;
      }

      this.itemsRequest = new DataPaginatedSearchRequest<T>(
        () => this.observable(this.itemsRequest.searchRequest),
        (sr) => this.observable(sr)
      );

      if (this.configData.searchRequest) {
        this.itemsRequest.setSearchRequest(
          searchRequestFromJSON(this.configData.searchRequest, {
            paginate: true,
            pageSize: 50
          })
        );
        this.itemsRequest.searchRequest.removeFilter(this.configData.idFilterCode);
      }
    }
  }

  seleccionarTodos(): void {
    if (this.observable) {
      const newSr = searchRequestFromJSON(this.itemsRequest.searchRequest);

      this.observable(newSr)
        .pipe(take(1))
        .subscribe((data) => {
          this.temporalSelection = {
            type: 'SearchRequest',
            count: data.count,
            response: data,
            searchRequest: this.itemsRequest.searchRequest,
            data: null,
            label: this.getLabelFromSearchRequest(this.itemsRequest.searchRequest)
          };
        });
    }

    //quitamos checkboxes
    this.listSelection = {
      type: 'empty',
      data: []
    };
  }

  ngOnDestroy(): void {
    this.subscriptionSR.unsubscribe();
  }

  ngOnInit(): void {
    this.initVariables();
    this._loadRequests();
    this.applyFilter();
    this.subscriptionSR = this.itemsRequest.searchRequest.onChange.asObservable().subscribe((changes) => {
      //la accion 10 equivale a nextPage() y la 11 a previousPage()
      if (changes.action === this.NEXT_PAGE_ACTION || changes.action === this.PREVIOUS_PAGE_ACTION) {
        this.listSelection = {
          type: 'empty',
          data: []
        };
      }
    });
  }

  public _loadRequests(): any {
    this.itemsRequest.load();
  }

  private initVariables(): void {
    this.filterDeclarations = this.config?.data?.filterDeclarations;
    const selection = this.config?.data?.selection;
    const response = this.config?.data?.response;

    if (selection) {
      if (selection.type === 'partial') {
        this.fetchSelectedItems(selection);
      } else {
        const newSr = searchRequestFromJSON(this.itemsRequest.searchRequest);

        newSr.setSearch(this.itemsRequest.searchRequest.inputsquery.search.value, this.itemsRequest.searchRequest.inputsquery.search.ids, false);
        this.temporalSelection = {
          type: selection.type == 'total' ? 'SearchRequest' : 'Items',
          count: selection?.data?.length | response?.count,
          response: selection.type == 'total' ? response : this.itemsRequest.data,
          searchRequest: newSr,
          data: selection?.data?.length ? selection?.data : response?.data,
          label: this.getLabelFromSearchRequest(newSr)
        };
      }
    }
  }

  onSearch(str: string): void {
    this.itemsRequest.searchRequest.setSearch(str, [], true);
  }

  isSelected(item: T): boolean {
    return this.temporalSelection?.data?.findIndex((d) => d.id === item.id) >= 0;
  }

  removeSaved(item: T): void {
    if (this.temporalSelection.type != 'Items') {
      return;
    }

    const index = (<T[]>this.temporalSelection.data).findIndex((sel) => sel.id === item.id);

    if (index >= 0) {
      this.temporalSelection.data.splice(index, 1);
      this.temporalSelection.count--;
      // Volvemos a llamar al método de buscar para que actualice los campos si existe termino de búsqueda y hemos eliminado.
      this.onSearchSelection(this.searchTerm);
    }

    if (this.temporalSelection.count === 0) {
      this.temporalSelection = null;
    }
  }

  removeAllSaved(): void {
    this.temporalSelection = null;
  }

  saveOneItem(item: T): void {
    const array = this.temporalSelection?.data ? [...this.temporalSelection?.data, item] : [item];

    this.temporalSelection = {
      type: 'Items',
      label: item[this.configData.itemCodeProp],
      data: array,
      count: array.length
    };
  }

  saveSelectedFilters(): void {
    this.saveSelectedItem({type: 'total', data: null});
  }

  saveSelectedItem(selection: DataSelection): void {
    const sr = this.itemsRequest.searchRequest.clone();

    switch (selection.type) {
      case 'page':
      case 'partial':
        const array = this.temporalSelection?.data ? [...this.temporalSelection?.data, ...selection.data] : selection.data;
        const arrayWithoutDuplicates = array.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);

        this.temporalSelection = {
          type: 'Items',
          data: arrayWithoutDuplicates,
          count: arrayWithoutDuplicates.length
        };
        break;
      case 'total':
        this.temporalSelection = {
          type: 'SearchRequest',
          searchRequest: sr,
          label: this.getLabelFromSearchRequest(sr),
          count: this.itemsRequest.data.count,
          response: this.itemsRequest.data
        };

        this.clearFiltersAndSelection();
        break;
    }

    this.listSelection = {
      type: 'empty',
      data: []
    };
  }

  filterCount(searchRequest: SearchRequest): number {
    return Object.keys(searchRequest?.filterStatus).filter((f) => Object.keys(this.filterDeclarations).includes(f)).length;
  }

  getLabelFromSearchRequest(searchRequest: SearchRequest): string {
    if (!searchRequest.filterStatus) {
      return null;
    }

    const filterStatus = Object.entries(searchRequest.filterStatus).filter((e) => Object.keys(this.filterDeclarations).includes(e[0]));

    if (!filterStatus) {
      return null;
    }

    if (!Object.keys(filterStatus).length) {
      return `<span class="flex truncate space-x-1"> <strong>${this.transloco.translate('common.seleccion-total')}</strong></span>`;
    }

    return filterStatus.reduce((o, a) => {
      o += `<span class="flex truncate space-x-1"> <strong>${this.transloco.translate(this.filterDeclarations[a[0]]?.label)}:</strong> <span class="truncate">${a[1].join(', ')};</span> </span>`;

      return o;
    }, '');
  }

  clearFiltersAndSelection(): void {
    this.filterList.clearSelection();
  }

  onChangeFilter(event: {id: string; value: any}): void {
    this.itemsRequest.searchRequest.toggleFilter(event.id, event.value);
    this.itemsRequest.load();
  }

  applyFilter(): void {
    switch (this.temporalSelection?.type) {
      case 'SearchRequest':
        this.searchSelection = {
          selection: {type: 'total', data: []},
          searchRequest: this.temporalSelection.searchRequest,
          selectionCount: this.temporalSelection.count,
          response: this.itemsRequest.data
        };
        break;
      case 'Items':
        this.searchSelection = {
          selection: {
            type: this.temporalSelection.count ? 'partial' : 'empty',
            data: this.temporalSelection.data
          },
          searchRequest: null,
          selectionCount: this.temporalSelection.count,
          response: null
        };
        break;
      default:
        this.searchSelection = {
          selection: {type: 'empty', data: null},
          searchRequest: null,
          selectionCount: 0,
          response: null
        };
    }
  }

  aplicarSeleccion(): void {
    this.applyFilter();
    this.ref.close(this.searchSelection);
  }

  onChangeSelection(selection: DataSelection): void {
    this.listSelection = selection;
  }

  onSearchSelection(searchTerm: string): void {
    this.searchTerm = searchTerm;
    if (!searchTerm) {
      this.filteredSelection = null;
    } else {
      if (this.temporalSelection?.data) {
        this.filteredSelection = this.temporalSelection.data.filter((item) => {
          (this.configData.itemSearchProps || []).forEach((s) => {
            if (get(item, s).toLowerCase().includes(searchTerm.toLowerCase())) {
              return true;
            }
          });
        });
      }
    }
  }

  openCsvCopy(): void {
    const dialog = this.dialogService.open(CsvCopyEmisoresComponent, {
      data: {
        codeFilter: this.configData.codeFilterCode,
        serviceCall: this.observable
      },
      dismissableMask: true,
      showHeader: false,
      style: {height: '60%', width: '50%'}
    });

    dialog.onClose.pipe(take(1)).subscribe((codigosArray: T[]) => {
      if (this.itemsRequest.data.data && this.itemsRequest.data.data.length) {
        const selection: DataSelection = {
          type: 'partial',
          data: codigosArray
        };

        if (codigosArray) {
          this.saveSelectedItem(selection);
        }
      }
    });
  }

  fetchSelectedItems(selection: DataSelection): void {
    const sr = searchRequestFromJSON(this.itemsRequest.searchRequest);

    sr.pagination = {page: 0, pageSize: 9999};
    sr.addFilter(
      this.configData.idFilterCode,
      selection.data.map((d) => d.id),
      false
    );
    this.observable(sr).subscribe((response: PaginatedResponse<T>) => {
      this.temporalSelection = {
        type: 'Items',
        count: response?.count,
        response: response,
        data: response?.data
      };
    });
  }

}
