import {Injectable} from '@angular/core';

import {Store} from '@ngxs/store';
import {AuthState} from '@store/auth.store';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {

  constructor(private store: Store) {}

  canActivate(): boolean {
    return this.store.selectSnapshot(AuthState.isAdminUser);
  }

}
