import {ElementRef, Injectable} from '@angular/core';
import * as L from 'leaflet';
import {LatLngTuple} from 'leaflet';
import 'leaflet.markercluster';
import {DatosMapaModel} from '@shared/models/datos-mapa.model';

@Injectable()
export class MapService {

  map: L.Map;
  private attribution = '&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors';

  renderSingleMap = (el: ElementRef, data: DatosMapaModel): void => {
    if (data) {
      let marker;

      if (data.icon) {
        marker = L.marker([data.coordenadas.y, data.coordenadas.x], {icon: data.icon});
      } else {
        marker = L.marker([data.coordenadas.y, data.coordenadas.x]);
      }

      let popupHTML = '<b>' + data.nombre + '</b>';

      if (data.actions) {
        for (const action of data.actions) {
          popupHTML += '<button  onclick="location.href=\'' + action.url + '\'" class="az-button size-sm az-button-primary az-button-block my-2">' + action.label + '</button>';
        }
      }

      marker.bindPopup(popupHTML);
      marker.addTo(this.map, {icon: data.icon});
      L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png', {
        attribution: '',
        subdomains: 'abcd',
        maxZoom: 19
      }).addTo(this.map);
      this.panMapTo(new L.LatLng(data.coordenadas.y, data.coordenadas.x));
      window.dispatchEvent(new Event('resize')); // fix para que aparezca centrado el mapa al cargar
    }
  };

  createMap(el: ElementRef): void {
    this.map = L.map(el.nativeElement);
  }

  renderMultipleMap = (el: ElementRef, data: DatosMapaModel[]): void => {
    if (data && data.length) {
      // this.map.setView([0, 0], 0);
      data = data.filter((item) => {
        return item.coordenadas && item.coordenadas.x !== null && item.coordenadas.y !== null;
      });
      const bounds: LatLngTuple[] = data.map((location: {nombre: string; coordenadas: {x: number; y: number}; icon: any}) => {
        return [location.coordenadas.y, location.coordenadas.x];
      });

      const markers = L.markerClusterGroup();

      if (bounds.length > 0) {
        for (const location of data) {
          let marker: L.Marker;

          if (location.icon) {
            marker = L.marker([location.coordenadas.y, location.coordenadas.x]);
          } else {
            marker = L.marker([location.coordenadas.y, location.coordenadas.x]);
          }

          let popupHTML = '<b>' + location.nombre + '</b>';

          if (location.actions) {
            for (const action of location.actions) {
              popupHTML += '<button  onclick="location.href=\'' + action.url + '\'" class="az-button size-sm az-button-primary az-button-block my-2">' + action.label + '</button>';
            }
          }

          marker.bindPopup(popupHTML);
          markers.addLayer(marker);
        }
      }

      this.map.addLayer(markers);

      L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
        subdomains: 'abcd',
        maxZoom: 19
      }).addTo(this.map);
      window.dispatchEvent(new Event('resize')); // fix para que aparezca centrado el mapa al cargar
      this.map.fitBounds(
        bounds.filter((value) => value[0] && value[1]),
        {maxZoom: 6, easeLinearity: 2}
      );
    }
  };

  panMapTo(latLng: L.LatLng, zoom = 16): void {
    this.map.setZoom(zoom);
    this.map.panTo(latLng);
  }

  zoomToMarker(latLng: L.LatLng): void {
    this.map.fitBounds([[latLng.lat, latLng.lng]]);
    this.panMapTo(latLng, 100);
  }

}
