import {ControlValueAccessor} from '@angular/forms';
import {DataPaginatedSearchRequest, PaginatedResponse, SearchRequest} from '@azigrene/searchrequest';
import {Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {AzModalService} from '@azigrene/components';
import {Observable} from 'rxjs';
import {DataFilterDeclaration, DataSelection, DataSelectionType} from '@azigrene/data-manager';
import {take} from 'rxjs/operators';
import {SearchInputTemplateEnum} from '@shared/templates/search-input/search-input-template.enum';
import {Store} from '@ngxs/store';
import {SeleccionMultipleComponent} from '@shared/components/seleccion/seleccion-multiple.component';
import {SeleccionIndividualComponent} from '@shared/components/seleccion/seleccion-individual/seleccion-individual.component';

export interface SearchConfig<T> {
  selection: DataSelection;
  multipleSelection: boolean;
  selected: T[];
  searchRequest: SearchRequest;
  selectionCount: number;
  observable?: (sr) => Observable<PaginatedResponse<T>>;
  response: PaginatedResponse<T>;
  filterDeclarations: any;
  tipoSeleccion: number;
  listItemTemplate: any;
  itemCodeProp: string;
  itemLabelProp: string;
  itemSearchProps: string[];
  idFilterCode: string;
  codeFilterCode: string;
}

export interface SearchSelection<T> {
  selection: {type: DataSelectionType; data: any[]};
  selectionCount?: number;
  searchRequest: SearchRequest;
  response: PaginatedResponse<T>;
}

export interface SelectionComponentStatus {
  selection: DataSelection;
  request?: SearchRequest;
}

@Directive()
export abstract class SearchComponent<T> implements ControlValueAccessor, OnChanges {

  @Input() disabled = false;
  @Input() adminOptions = false;
  @Input() inputId = '';
  @Input() multiple = false;
  @Input() selected: any[];
  @Input() selectedProperty = null;

  @Input() currentValue: {ids: number[]; request: SearchRequest} | SelectionComponentStatus;
  @Input() observable: (sr) => Observable<PaginatedResponse<T>>;
  @Output() itemsSelected = new EventEmitter<any>();
  @Output() changeSelection = new EventEmitter<SearchSelection<T>>();
  @Output() request = new EventEmitter<SearchRequest>();
  @Input() buttonClass = '';
  @Input() template: number = null;
  @ViewChild('listItemTemplate') listItemTemplate: TemplateRef<HTMLElement>;

  puntosSuministro: any[] = [];

  value: number;
  model: {
    label?: any;
    fields: any;
    value: any;
  };

  searchRequest = new SearchRequest(null, {paginate: false});
  selection: SearchSelection<T>;
  selectionCount: number;
  searchInputEmisorTemplate = SearchInputTemplateEnum;

  onChange = (_: any) => {};
  onTouch: () => unknown = () => {
    return;
  };

  constructor(private _dialogService: AzModalService, private _store?: Store) {}

  abstract getFilterDeclarations(): {[id: string]: DataFilterDeclaration};

  abstract getObservable(): (sr: SearchRequest) => Observable<PaginatedResponse<T>>;

  abstract getTipoSeleccion(): number;

  abstract getListItemTemplate(): TemplateRef<HTMLElement>;

  abstract getLabelProperty(): string;

  abstract getCodeProperty(): string;

  abstract getSearchProperties(): string[];

  /*private setGlobalSearchRequest() {TODO: Revisar si aplica
        if (this.organismoState) {
            const {searchRequestGlobal, seleccionGlobal} = this.organismoState;
            const globalSelection: SearchSelection<T> = cloneDeep(this._store.selectSnapshot(seleccionGlobal));

            this.searchRequest = this._store.selectSnapshot(searchRequestGlobal);

            if (globalSelection) {
                const selectionData = globalSelection.selection.data;

                if (!this.multiple) {
                    globalSelection.selection.data = [selectionData[0]];
                }

                this.selection = globalSelection;
                this.selectionCount = selectionData.length;

                this.onSelect(selectionData);
            }
        }
    }*/

  onSelect(value: any): void {
    if (value && this.selectedProperty) {
      if (value.length) {
        value = value.map((v) => v[this.selectedProperty]);
      } else {
        value = value[this.selectedProperty];
      }
    }

    this.onTouch();
    this.onChange(value);
    this.writeValue(value);
    this.changeSelection.emit(this.selection);
  }

  writeValue(value: number): void {
    if (value) {
      this.value = value || null;
    } else {
      this.value = null;
    }
  }

  registerOnChange(fn: () => unknown): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  searchModal(): void {
    if (!this.disabled) {
      let dialog;
      const config: SearchConfig<T> = {
        selection: this.selection && this.template !== SearchInputTemplateEnum.ADD ? this.selection.selection : null,
        multipleSelection: this.multiple,
        selected: this.selected,
        selectionCount: this.template === SearchInputTemplateEnum.ADD ? undefined : this.selectionCount,
        searchRequest: this.template === SearchInputTemplateEnum.ADD ? undefined : this.selection?.searchRequest,
        response: this.selection ? this.selection.response : null,
        observable: this.observable ? this.observable : this.getObservable(),
        filterDeclarations: this.getFilterDeclarations(),
        tipoSeleccion: this.getTipoSeleccion(),
        listItemTemplate: this.getListItemTemplate(),
        itemCodeProp: this.getCodeProperty(),
        itemLabelProp: this.getLabelProperty(),
        itemSearchProps: this.getSearchProperties(),
        idFilterCode: this.getIdFilter(),
        codeFilterCode: this.getCodeFilter()
      };

      if (!this.multiple) {
        dialog = this._dialogService.open(SeleccionIndividualComponent, {
          data: config,
          dismissableMask: true,
          showHeader: false,
          style: {height: '80%', width: '70%'}
        });
      } else {
        dialog = this._dialogService.open(SeleccionMultipleComponent, {
          data: config,
          dismissableMask: true,
          showHeader: false,
          style: {height: '80%', width: '70%'}
        });
      }

      dialog.onClose.pipe(take(1)).subscribe((data: SearchSelection<T>) => {
        if (data) {
          if (!this.multiple) {
            this.selected = data.selection.data;
            this.selection = data;
            this.onSelect(this.selected[0]);

            return;
          }

          this.selection = data;
          this.selectionCount = 0;
          switch (data.selection.type) {
            case 'empty':
              this.selected = [];
              this.selectionCount = undefined;
              this.changeSelection.emit(this.selection);
              break;
            case 'page':
            case 'partial':
              this.selected = data.selection.data;
              this.selectionCount = data.selectionCount;
              if (this.selected.length) {
                this.onSelect(this.multiple ? this.selected : this.selected[0]);
              }

              break;
            case 'total':
              this.searchRequest = data.searchRequest;
              this.selectionCount = data.response.count;
              this.changeSelection.emit(this.selection);
              this.request.emit(this.searchRequest);
              break;
          }
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected && changes.selected.currentValue) {
      if (changes.selected.currentValue instanceof Array) {
        this.selection = {
          selection: {
            type: 'partial',
            data: changes.selected.currentValue
          },
          searchRequest: this.searchRequest,
          response: null
        };
      } else {
        this.selection = {
          selection: {
            type: 'partial',
            data: [changes.selected.currentValue]
          },
          searchRequest: this.searchRequest,
          response: null
        };
      }
    }

    if (changes.currentValue?.currentValue?.ids) {
      this.processCurrentValueIds(this.isNumberArray(changes.currentValue.currentValue.ids) ? changes.currentValue.currentValue.ids : changes.currentValue.currentValue.ids.map((id) => ({id})));
    } else if (changes.currentValue?.currentValue) {
      this.processCurrentValueDataSelection(changes.currentValue.currentValue?.selection, changes.currentValue.currentValue.request);
    }
  }

  processCurrentValueIds(ids: number[]): void {
    const auxRequest = new DataPaginatedSearchRequest<T>(
      () => this.observable(auxRequest.searchRequest),
      (sr) => this.observable(sr)
    );
    const sr = auxRequest.searchRequest;

    if (ids && ids.length && ids.length) {
      sr.addFilter(this.getIdFilter(), ids, false);
      sr.pagination = {page: 0, pageSize: 9999};
      this.selected = ids.map((id) => ({id}));
      if (this.observable) {
        this.observable(sr)
          .pipe(take(1))
          .subscribe((data) => {
            this.selection = {
              selection: {
                type: 'partial',
                data: data.data
              },
              selectionCount: data.count,
              searchRequest: null,
              response: null
            };
          });
      }
    } else {
      this.selection = {
        selection: {
          type: 'empty',
          data: []
        },
        searchRequest: sr,
        response: null
      };

      this.searchRequest = sr;
    }
  }

  processCurrentValueDataSelection(selection: DataSelection, request: SearchRequest): void {
    if (selection && selection.type !== 'total') {
      this.selected = selection.data;
      this.selection = {
        selection: {
          type: selection.type,
          data: selection.data
        },
        searchRequest: request,
        response: null
      };
    } else if (request) {
      if (this.observable) {
        this.observable(request)
          .pipe(take(1))
          .subscribe((data) => {
            this.selection = {
              selection: {
                type: 'total',
                data: []
              },
              searchRequest: request,
              response: data
            };
          });
      } else {
        this.selection = {
          selection: {
            type: 'empty',
            data: []
          },
          searchRequest: request,
          response: null
        };
      }

      this.searchRequest = request;
    }
  }

  isNumberArray(array: any[]): boolean {
    return array.every((element) => {
      return !isNaN(element);
    });
  }

  abstract getIdFilter(): string;

  abstract getCodeFilter(): string;

}
