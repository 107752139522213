import {environment} from '@env/environment';
import {Injectable} from '@angular/core';
import {AbstractPaginatedService} from '@app/shared/interfaces/service-classes';
import {AlcanceModel} from '@shared/models/alcance.model';
import {Observable} from 'rxjs';

@Injectable()
export class AlcancesUserService extends AbstractPaginatedService<AlcanceModel, undefined> {
  buildUrlBase(): string {
    return `${environment.api.admin.alcances.user}`;
  }

  getAll(): Observable<AlcanceModel[]> {
    return this.http.get<AlcanceModel[]>(this.buildUrlBase() + '/all');
  }
}
