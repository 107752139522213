
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Select, Store} from '@ngxs/store';
import {take} from 'rxjs/operators';
import { FuncionModel } from "@shared/models/funcion.model";
import { Observable } from "rxjs";
import { AvailableThemes, CuentaModel } from "@shared/models/cuenta.model";
import { MainState } from "@store/main.store";
import { PlanModel } from "@shared/models/plan.model";
import { MiembroModel } from "@shared/models/miembro.model";
import { DropdownModel } from "@shared/models/dropdown.model";

@Component({
  selector: 'app-cuenta-form',
  templateUrl: './cuenta-form.component.html'
})
export class CuentaFormComponent implements OnInit {

  @Select(MainState.planes) optionsPlanes$: Observable<DropdownModel<PlanModel>[]>;
  @Select(MainState.miembro) miembroSeleccionado$: Observable<MiembroModel>;
  @Select(MainState.funciones) funciones$: Observable<FuncionModel[]>;

  @Input() cuenta: CuentaModel;
  @Input() deleteBtn = true;
  @Input() cuentaActivaCheckbox = false;
  @Input() excluidos: any = {};
  @Input() backoffice = false;
  @Output() savedCuenta = new EventEmitter<any>();
  @Output() deletedCuenta = new EventEmitter<number>();

  cuentaForm: FormGroup;
  uploadedFiles: any[] = [];
  optionsPlanes;
  funciones = [];

  constructor(public router: Router, public store: Store, private formBuilder: FormBuilder) {
    this.cuentaForm = this.formBuilder.group({
      id: new FormControl(null),
      nombre: new FormControl(null, {
        validators: [Validators.required, Validators.minLength(3)],
        updateOn: 'blur'
      }),
      active: new FormControl(true),
      planId: new FormControl(null, {validators: [Validators.required], updateOn: 'change'}),
      logoBytes: new FormControl(null),
      features: new FormControl(null),
      tema: [AvailableThemes['default-theme']]
    });
  }

  ngOnInit(): void {
    if (this.cuenta) {

      this.cuentaForm.patchValue({
        id: this.cuenta.id,
        nombre: this.cuenta.nombre,
        active: this.cuenta.active,
        planId: this.cuenta.plan ? this.cuenta.plan.id : null,
        logoBytes: this.cuenta.logoBytes,
        features: this.cuenta.features,
        tema: this.cuenta.theme || AvailableThemes['default-theme']
      });
    }

    this.funciones = this.store.selectSnapshot(MainState.funciones);
    this.updateFeatures(this.cuenta.plan.features);

    this.optionsPlanes$.pipe(take(1)).subscribe((planes) => {
      this.optionsPlanes = planes;
    });
  }

  save(): void {
    this.savedCuenta.emit(this.cuentaForm.value);
  }

  delete(): void {
    this.deletedCuenta.emit(this.cuentaForm.get('id').value);
  }

  onChangePlan(planId: number): void {
    const planSelected = this.optionsPlanes.find((p) => p.obj.id === planId);

    this.updateFeatures(planSelected.obj.features);
  }

  updateFeatures(planFeatures: string[]): void {
    for (const funcion of this.funciones) {
      const index = planFeatures.findIndex((p) => p === funcion.nombre);
      const indexInForm = this.cuentaForm.value.features.findIndex((p) => p === funcion.nombre);

      funcion.readOnly = index > -1;
      funcion.value = funcion.nombre;
      funcion.isPresent = index > -1 || indexInForm > -1;
      funcion.label = funcion.nombre;
    }

    this.cuentaForm.patchValue({
      features: this.funciones.filter((funcion) => funcion.isPresent).map((funcion) => funcion.nombre)
    });
  }

}
