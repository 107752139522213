import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {LoginResponse} from '@shared/models/auth.model';
import {UsuarioLoginModel} from '@shared/models/usuario-login.model';

export interface SSOConfig {
  active: boolean;
  config: {
    ssoUrl: string;
    email: string;
  };
}

export interface AuthStateModel {
  token: string;
  user: UsuarioLoginModel;
  sso: SSOConfig;
}

export class LoginSuccessful {

  static readonly type = '[Auth] LoginSuccessful';

  constructor(public readonly user?: LoginResponse) {}

}

export class UserRetrieved {

  static readonly type = '[Auth] UserRetrieved';

  constructor(public readonly user?: UsuarioLoginModel) {}

}

export class LoginSSOSuccessful {

  static readonly type = '[Auth] LoginSSOSuccessful';

  constructor(
    public readonly config?: {
      ssoUrl: string;
      email: string;
    }
  ) {}

}

export class LogoutAction {

  static readonly type = '[Auth] LogoutAction';

}

export class StartLogoutAction {

  static readonly type = '[Auth] StartLogoutAction';

}

@Injectable()
@State<AuthStateModel>({
  name: 'hc_auth',
  defaults: {
    token: null,
    user: null,
    sso: {
      active: false,
      config: null
    }
  }
})
export class AuthState {

  @Selector()
  static token$(ctx: AuthStateModel): string {
    return ctx.token;
  }

  @Selector()
  static ssoConfig(ctx: AuthStateModel): SSOConfig | null {
    return ctx.sso ? ctx.sso : null;
  }

  @Selector()
  static user$(ctx: AuthStateModel): UsuarioLoginModel {
    return ctx.user;
  }
  @Selector()
  static isAdminUser(ctx: AuthStateModel): boolean {
    return ctx.user?.rol == 'ADMIN';
  }

  @Action(LoginSuccessful)
  loginSuccessful(ctx: StateContext<AuthStateModel>, action: LoginSuccessful): void {
    ctx.patchState({
      token: action?.user?.token,
      user: action.user
    });
  }

  @Action(UserRetrieved)
  userRetrieved(ctx: StateContext<AuthStateModel>, action: UserRetrieved): void {
    ctx.patchState({
      user: action.user
    });
  }

  @Action(LogoutAction)
  logout(ctx: StateContext<AuthStateModel>): void {
    ctx.patchState({
      token: null,
      user: null,
      sso: {
        active: false,
        config: null
      }
    });
  }

  @Action(LoginSSOSuccessful)
  loginSSOSuccessful(ctx: StateContext<AuthStateModel>, action: LoginSSOSuccessful): void {
    ctx.patchState({
      sso: {
        active: true,
        config: action.config
      }
    });
  }

}
