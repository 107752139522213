import { DatePipe } from '@angular/common';
import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MainState } from '@app/store/main.store';
import { AzModalConfig } from '@azigrene/components';
import { DataColumnDeclaration, DataColumnType } from '@azigrene/data-manager';
import { AzPipesService } from '@azigrene/pipes';
import { SearchRequest, SearchRequestOptions } from '@azigrene/searchrequest';
import { Store } from '@ngxs/store';
import { DynamicTablePropertiesTypeEnum, getColumnTypeFromTipo } from '@shared/components/dynamic-field-manager/data/dynamic-table-properties-type.enum';
import { DynamicTableFieldModel } from '@shared/components/dynamic-field-manager/models/dynamic-table-property.model';
import { RolAplicacion } from '@shared/enums/rol-aplicacion.enum';
import { MiembroModel } from '@shared/models/miembro.model';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import * as EmailValidator from 'email-validator';
import get from 'lodash/get';
import { Permiso } from './enums/permisos.enum';


export class Factory {

  static create<T>(item?: unknown): T {
    let type: new (item?: unknown) => T;

    return new type(item);
  }

}

export function procesarFilterQuery(activatedRoute: ActivatedRoute, filterTypes: { [id: string]: 'number' | 'number[]' | 'string[]' | 'string' }, searchRequest: SearchRequest): void {
  const params = activatedRoute.snapshot.queryParams;

  if (params) {
    Object.keys(filterTypes).forEach((f) => {
      if (params[f]) {
        switch (filterTypes[f]) {
          case 'number':
            searchRequest.addFilter(f, +params[f]);
            break;
          case 'number[]':
            searchRequest.addFilter(
              f,
              params[f].split(',').map((v) => +v)
            );
            break;
          case 'string[]':
            searchRequest.addFilter(f, params[f].split(','));
            break;
          default:
            searchRequest.addFilter(f, params[f]);
            break;
        }
      }
    });
  }
}

export function procesarFilterQueryByParams(searchRequest: SearchRequest, params: Params, filterTypes?: { [id: string]: 'number' | 'number[]' | 'string[]' | 'string' }): void {
  if (params) {
    for (const param in params) {
      if (params.hasOwnProperty(param)) {
        if (!filterTypes || !filterTypes[param]) {
          searchRequest.addFilter(param, params[param].split(','), false);
        } else {
          switch (filterTypes[param]) {
            case 'number':
              searchRequest.addFilter(param, +params[param], false);
              break;
            case 'number[]':
              searchRequest.addFilter(
                param,
                params[param].split(',').map((v) => +v),
                false
              );
              break;
            case 'string[]':
              searchRequest.addFilter(param, params[param].split(','), false);
              break;
            default:
              searchRequest.addFilter(param, params[param], false);
              break;
          }
        }
      }
    }
  }
}

export const QueryParamUtils = {
  addQueryParam: (param: string, value: any, router: Router, route: ActivatedRoute): void => {
    router.navigate([], {
      relativeTo: route,
      queryParams: { [param]: value },
      queryParamsHandling: 'merge'
    });
  },
  removeQueryParam: (param: string, router: Router, route: ActivatedRoute): void => {
    const params: Params = JSON.parse(JSON.stringify(route.snapshot.queryParams));

    delete params[param];
    router.navigate([], { relativeTo: route, queryParams: params || null });
  }
};

export function isMobile(): boolean {
  let check = false;

  ((a) => {
    // eslint-disable-next-line max-len
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    ) {
      check = true;
    }
  })(navigator.userAgent || navigator.vendor || window['opera']);

  return check;
}

export function flattenTree(treeObj, idAttr = 'id', parentAttr = 'parentCode', childrenAttr = 'children', levelAttr = 'level'): any[] {
  function flattenChild(childObj, parentCode, level): any[] {
    let array = [];
    const childCopy = Object.assign({}, childObj);

    childCopy[levelAttr] = level;
    childCopy[parentAttr] = parentCode;
    delete childCopy[childrenAttr];
    array.push(childCopy);

    array = array.concat(processChildren(childObj, level));

    return array;
  }

  function processChildren(obj, level = 0): any[] {
    let array = [];

    obj[childrenAttr].forEach((childObj) => {
      array = array.concat(flattenChild(childObj, obj[idAttr], level + 1));
    });

    return array;
  }

  const result = processChildren(treeObj);

  return result;
}

export function parseDataTypes(data: any[], declarationMap: { [id: string]: { type: DataColumnType } }): any[] {
  return data.map((value) => {
    const obj: any = {};

    for (const columnsKey in declarationMap) {
      const val = get(value, columnsKey);

      switch (declarationMap[columnsKey].type) {
        case DataColumnType.currency:
          obj[columnsKey] = this.pipeService.transformCurrency(val);
          break;
        case DataColumnType.date:
          obj[columnsKey] = this.pipeService.transformDate(val);
          break;
        case DataColumnType.boolean:
          obj[columnsKey] = this.pipeService.transformBoolean(val);
          break;
        case DataColumnType.datetime:
          obj[columnsKey] = this.pipeService.transformDatetime(val);
          break;
        case DataColumnType.number:
          obj[columnsKey] = this.pipeService.transformNumber(val);
          break;
        case DataColumnType.percent:
          obj[columnsKey] = this.pipeService.transformPercent(val);
          break;
        case DataColumnType.tag:
          obj[columnsKey] = val;
          break;
        default:
          obj[columnsKey] = val;
      }
    }

    obj.id = value.id;

    return obj;
  });
}

export type DetailType = 'modal' | 'route';

export function getIdFromModalOrRoute(paramName: string, config: AzModalConfig, route: ActivatedRoute): { value: any; mode: DetailType } {
  if (config.data && config.data[paramName]) {
    return { value: config.data[paramName], mode: 'modal' };
  } else {
    return { value: route.snapshot.params[paramName], mode: 'route' };
  }
}

export function mergeSearchRequests(base: SearchRequest, ...s1: SearchRequest[]): SearchRequest {
  // @ts-ignore
  const searchRequest = new SearchRequest(null, { paginate: true, pageSize: base.pagination?.pageSize || 50 });

  searchRequest.setFilterStatus(base.filterStatus, false);
  searchRequest.setSortStatus(base.sortStatus, false);
  searchRequest.setPage(base.pagination?.page, false);
  searchRequest.inputsquery.search.value = base.inputsquery.search.value;
  searchRequest.inputsquery.search.ids = base.inputsquery.search.ids;
  searchRequest.toRequest();
  s1.forEach((sr) => {
    if (!sr) {
      return;
    }

    const _s = cloneObject(searchRequest.filterStatus);

    searchRequest.mergeFilters(sr, _s, false);
  });

  return searchRequest;
}

export function removePuntoSuministroFilters(base: SearchRequest): void {
  Object.keys(base.filterStatus).forEach((key) => {
    if (key.indexOf('ps_') >= 0) {
      base.removeFilter(key, false);
    }
  });
}

export function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function searchRequestFromJSON(req: SearchRequest, options?: SearchRequestOptions): SearchRequest {
  const sr: SearchRequest = new SearchRequest(null, options ?? req.options);

  req.inputsquery.filters.forEach((f) => {
    sr.addFilter(f.id, f.value, false);
  });
  req.inputsquery.sorts.forEach((f) => {
    sr.addSort(f.id, f.value, false);
  });

  return sr;
}

export function hasPermission(permiso: Permiso | string, miembro: MiembroModel): boolean {
  if (miembro) {
    if (miembro.usuario.rolAplicacion === RolAplicacion.ADMIN.codigo || miembro.rol.id === RolAplicacion.ADMIN.id) {
      return true;
    }

    if (miembro.permisos.includes(permiso)) {
      return true;
    }
  }

  return false;
}

export function hasPermisionEdit(store: Store, permiso: Permiso): boolean {
  return store.selectSnapshot(MainState.hasPermiso)(permiso);
}

export function getRedirectData(url: string): { redirect_url: string; redirect_params: Params } {
  const redirectUrl = url.split('?')[0];
  const redirectParams = url.indexOf('?') >= 0 ? url.split('?')[1] : '';
  const paramsArray = redirectParams.split('&');
  const queryParams = {};

  for (const param of paramsArray) {
    queryParams[param.split('=')[0]] = param.split('=')[1];
  }

  return { redirect_url: redirectUrl, redirect_params: queryParams };
}

export const FormUtils = {
  validators: {
    atLeastOneRequired: (field1: string, field2: string): ValidatorFn => (group: FormGroup) => {
      if (group) {
        if (group.controls[field1].value || group.controls[field2].value) {
          return null;
        }
      }

      return { 'atleast-one-required': [field1, field2] };
    },
    conditionTrue: (condition: boolean): ValidatorFn => (control: AbstractControl) => {
      if (condition) {
        return null;
      }

      return { 'condition-not-true': true };
    },
    requireLengthOf: (length: number): ValidatorFn => (control: AbstractControl) => {
      if (control.value && control.value.length && control.value.length == length) {
        return null;
      }

      return { 'required-length': length };
    },
    indexesNotNull: (...indexes: number[]): ValidatorFn => (control: AbstractControl) => {
      if (control.value && control.value.length && control.value.filter((v, i) => indexes.includes(i) && v == undefined && v == null).length == 0) {
        return null;
      }

      return { 'indexes-null': true };
    },
    allValuesNotNull: (control: AbstractControl): { 'all-values-not-null': boolean } => {
      if (control.value && control.value.length && control.value.filter((v) => v == undefined && v == null).length == 0) {
        return null;
      }

      return { 'all-values-not-null': true };
    },
    onlyNumbers: (control: AbstractControl): { 'only-numbers': boolean } => {
      const value: string = control.value || '';
      const valid = value.match(/^[0-9]*$/);

      return valid ? null : { 'only-numbers': true };
    },
    checkMail(control: AbstractControl): { 'mail-invalid': boolean } {
      return control.value && control.value.length > 2 && !EmailValidator.validate(control.value) ? { 'mail-invalid': true } : null;
    },
    checkPassword(control: AbstractControl): { 'password-invalid': boolean } {
      const value: string = control.value || '';
      const valid = value.match(/^(?=.*[A-Za-zñÑ])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@^_`{|}~])[A-Za-zñÑ\d!"#$%&'()*+,-./:;<=>?@^_`{|}~]{8,}$/);

      return valid ? null : { 'password-invalid': true };
    },
    checkPercentageInRange(control: AbstractControl): { 'value-no-between-percentage': boolean } {
      if (control.value) {
        if (control.value < 0 || control.value > 1) {
          return { 'value-no-between-percentage': true };
        }
      }

      return null;
    },
    checkUrl(control: AbstractControl): { 'url-invalid': boolean } {
      const pattern = /^(http[s]?:\/\/)(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}(\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/; // fragment locator

      if (control.value && control.value.length > 2 && !pattern.test(control.value)) {
        return { 'url-invalid': true };
      }

      return null;
    }
  },
  functions: {}
};

export const FormValidatorGroups = {
  fechas: [Validators.required, FormUtils.validators.requireLengthOf(2), FormUtils.validators.indexesNotNull(0)],
  fechas_mandatory: [Validators.required, FormUtils.validators.requireLengthOf(2), FormUtils.validators.allValuesNotNull]
};

export const DateUtils = {
  withoutTimezone: (date: Date): Date => {
    const d = new Date(date);
    const timeZoneDifference = (d.getTimezoneOffset() / 60) * -1; //convert to positive value.

    d.setTime(d.getTime() + timeZoneDifference * 60 * 60 * 1000);

    return d;
  },
  isBefore: (thisOne: Date, thatOne: Date): boolean => {
    return isBefore(thisOne, thatOne);
  },
  isAfter: (thisOne: Date, thatOne: Date): boolean => {
    return isAfter(thisOne, thatOne);
  },
  isBeforeOrSame: (thisOne: Date, thatOne: Date): boolean => {
    return isBefore(thisOne, thatOne) || isSameDay(thisOne, thatOne);
  },
  isAfterOrSame: (thisOne: Date, thatOne: Date): boolean => {
    return isAfter(thisOne, thatOne) || isSameDay(thisOne, thatOne);
  },
  daysInRange: (date1: Date, date2: Date): number => {
    if (!date1 || !date2) {
      return 0;
    }

    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  },
  orderByDate: (array: any[], property?: string): any[] => {
    return array.sort((data1, data2) => new Date(data1[property]).getTime() - new Date(data2[property]).getTime());
  }
};

export const ColorUtils = {
  // Funcion de utilidad para seleccionar color del texto dependiendo del color de fondo
  pickTextColorBasedOnBgColorAdvanced(bgColor: string, lightColor: string, darkColor: string): string {
    if (!bgColor) {
      bgColor = '#e6e6e4';
    }

    const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    const uicolors = [r / 255, g / 255, b / 255];
    const c = uicolors.map((col) => {
      if (col <= 0.04928) {
        return col / 12.92;
      }

      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];

    return L > 0.179 ? darkColor : lightColor;
  }
};

export const DataUtils = {
  getByPath: (value, path) => get(value, path)
};

export const ChartUtils = {
  colors: ['#0284C7', '#34D399', '#FBBF24', '#F87171', '#94A3B8', '#22D3EE', '#818CF8', '#C084FC', '#FB7185']
};

export const StringUtils = {
  capitalizeFirstLetter: (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

export const NumberUtils = {
  getEuropeanFormat: (number: number, pipe: AzPipesService): string => {
    return pipe.transformNumber(number, '1.0-2');
  },
  getEuropenWithAllDecimals: (number: number, pipe: AzPipesService): string => {
    return pipe.transformNumber(number, '1.0-100');

  }
};

export function jsonClone(json: unknown): any {
  return JSON.parse(JSON.stringify(json));
}

export function formatDatos(datos: { [codigo: string]: unknown }, campos: DynamicTableFieldModel[], datePipe: DatePipe): { [codigo: string]: unknown } {
  if (datos && campos) {
    Object.keys(datos)?.forEach((dato) => {
      if (campos.filter((campo) => campo?.uuid === dato && campo?.tipo === DynamicTablePropertiesTypeEnum.FECHA)?.length === 1) {
        datos[dato] = datos[dato] ? datePipe.transform(datos[dato].toString(), 'yyyy-MM-dd') : datos[dato];
      }
    });

    return datos;
  } else {
    return {};
  }
}

export function getDatosColumns(campos: DynamicTableFieldModel[]): DataColumnDeclaration[] {
  const datosColumns: DataColumnDeclaration[] = [];

  campos?.map((campo) =>
    datosColumns.push({
      field: 'datos.' + campo.uuid,
      name: campo.nombre,
      type: getColumnTypeFromTipo(campo.tipo)
    })
  );

  return datosColumns;
}

export function joinCamposNombresByCommas(campos: DynamicTableFieldModel[]): string {
  let camposNombresByCommas = '';

  if (campos) {
    camposNombresByCommas = campos.map((campo) => campo.nombre).join(', ');
  }

  return camposNombresByCommas;
}

export function getCamposDinamicosColumns(campos: DynamicTableFieldModel[], defaultCampos: DynamicTableFieldModel[]): DataColumnDeclaration[] {
  let defaultCamposClone = defaultCampos ? jsonClone(defaultCampos) : defaultCampos;

  defaultCamposClone = campos ? defaultCamposClone?.filter((defaultCampo) => campos?.filter((campo) => campo?.nombre === defaultCampo?.nombre)?.length === 0) : defaultCamposClone;
  const datosColumns: DataColumnDeclaration[] = getDatosColumns(campos).concat(getDatosColumns(defaultCamposClone));

  return datosColumns;
}
