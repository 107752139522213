import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {PlanModel} from '@shared/models/plan.model';
import {Observable} from 'rxjs';
import {AbstractPaginatedService} from '@shared/interfaces/service-classes';
import {FuncionModel} from '@shared/models/funcion.model';
import {environment} from '@env/environment';

@Injectable()
export class PlanesUserService extends AbstractPaginatedService<PlanModel, undefined> {

  buildUrlBase(): string {
    return environment.api.admin.planes.user;
  }

  getAll(): Observable<PlanModel[]> {
    return this.http.get<PlanModel[]>(this.buildUrlBase() + '/all');
  }

  get(planId: number): Observable<PlanModel> {
    return this.http.get<PlanModel>(this.buildUrlBase() + '/' + planId + '/one');
  }

  allFunciones(): Observable<FuncionModel[]> {
    return this.http.get<FuncionModel[]>(this.buildUrlBase() + '/funciones').pipe(map((list) => list.map((f) => ({id: f.id, nombre: f.nombre, value: false, readonly: false}))));
  }

}
