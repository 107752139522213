import {environment} from '@env/environment';
import {Injectable} from '@angular/core';
import {AbstractPaginatedService} from '@app/shared/interfaces/service-classes';
import {DefaultFuenteEmisionModel} from '@shared/models/default-fuente-emision.model';
import {Observable} from 'rxjs';

@Injectable()
export class DefaultFuentesEmisionUserService extends AbstractPaginatedService<DefaultFuenteEmisionModel, undefined> {

  buildUrlBase(): string {
    return `${environment.api.admin.defaultFuenteEmision.user}`;
  }

  get(id: number): Observable<DefaultFuenteEmisionModel> {
    return this.getHttp().get<DefaultFuenteEmisionModel>(this.buildUrlBase() + '/' + id + '/one');
  }

}
