import {AfterViewInit, Component, ElementRef, EventEmitter, Input, IterableDiffers, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MapService} from '@shared/services/map.service';
import {AzNotifierService} from '@azigrene/components';
import * as L from 'leaflet';
import {LatLngTuple} from 'leaflet';
import {TranslocoService} from '@ngneat/transloco';
import {DatosMapaModel} from '@shared/models/datos-mapa.model';

@Component({
  selector: 'az-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements AfterViewInit, OnChanges {

  @ViewChild('map') map: ElementRef<L.Map>;

  @Input() data: DatosMapaModel[] = [];
  @Input() centerTo: {x: number; y: number};
  @Output() renderedMap = new EventEmitter();
  differ: any;

  constructor(public mapService: MapService, private differs: IterableDiffers, private translateService: TranslocoService, private notifierService: AzNotifierService) {
    L.Icon.Default.imagePath = 'assets/images/';
    this.differ = differs.find([]).create(null);
  }

  ngAfterViewInit(): void {
    this.mapService.createMap(this.map);
    this.renderMap();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.map && changes.data) {
      this.goToCoordinates(changes.data.currentValue[0]);
    }
  }

  reloadMap(data: DatosMapaModel[]): void {
    this.data = data;
    this.renderMap();
  }

  renderMap(): void {
    if (this.data && this.data.length > 1) {
      this.mapService.renderMultipleMap(this.map, this.data);
    } else if (this.data && this.data.length === 1) {
      this.mapService.renderSingleMap(this.map, this.data[0]);
    }

    setTimeout(() => {
      this.renderedMap.emit(true);
    }, 800);
  }

  invalidateSize(): void {
    this.mapService?.map?.invalidateSize();
  }

  goToCoordinates(item: DatosMapaModel): void {
    let latValid;
    let lngValid;

    this.notifierService.clear();
    if (item.coordenadas.y >= -90 && item.coordenadas.y <= 90) {
      this.data[0].coordenadas.y = item.coordenadas.y;
      latValid = true;
    } else {
      this.notifierService.showInfoError(this.translateService.translate('components.mapa-gis.latitud-invalida', {value: 90}));
      latValid = false;
    }

    if (item.coordenadas.x >= -180 && item.coordenadas.x <= 180) {
      this.data[0].coordenadas.x = item.coordenadas.x;
      lngValid = true;
    } else {
      this.notifierService.showInfoError(this.translateService.translate('components.mapa-gis.longitud-invalida', {value: 180}));
      lngValid = false;
    }

    if (latValid && lngValid) {
      this.mapService.panMapTo(new L.LatLng(this.data[0].coordenadas.y, this.data[0].coordenadas.x));
    }
  }

  centerMapByData(data: DatosMapaModel[]): void {
    if (data?.length > 1) {
      const bounds: LatLngTuple[] = data.map((location: {nombre: string; coordenadas: {x: number; y: number}; icon: any}) => {
        return [location.coordenadas.x, location.coordenadas.y];
      });

      this.mapService?.map?.fitBounds(
        bounds.filter((value) => value[0] && value[1]),
        {maxZoom: 6, easeLinearity: 2}
      );
    } else if (data?.length === 1) {
      this.goToCoordinates(data[0]);
    }
  }

}
