import { DataFilterDeclaration, DataFilterTypes } from "@azigrene/data-manager";

export const filterDeclaration: {[id: string]: DataFilterDeclaration} = {
  tipo_emisor_nombre_in: {
    id: 'tipo_emisor_nombre_in',
    type: DataFilterTypes.AsyncMultiple,
    placeholder: 'common.tipo-emisor',
    label: 'common.tipo-emisor',
    alwaysVisible: true
  },
  zona_nombre_in: {
    id: 'zona_nombre_in',
    type: DataFilterTypes.AsyncMultiple,
    placeholder: 'common.zona',
    label: 'common.zona',
    alwaysVisible: true
  },
  area_organizativa_nombre_in: {
    id: 'area_organizativa_nombre_in',
    type: DataFilterTypes.AsyncMultiple,
    placeholder: 'common.area-organizativa',
    label: 'common.area-organizativa',
    alwaysVisible: true
  },
};

