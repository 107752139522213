import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { first, flatMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { TranslocoService } from '@ngneat/transloco';
import { AuthState } from '@app/store/auth.store';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor(private store: Store, private router: Router, private translateService: TranslocoService) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.store.select(AuthState.token$).pipe(
      first(),
      flatMap(token => {
        const authReq = request.clone({
          setHeaders: {'Accept-Language': this.translateService.getActiveLang() || 'es'}
        });


        return next.handle(authReq);
      })
    );
  }

}
