import { Component, OnInit } from "@angular/core";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Actions, ofActionSuccessful, Store} from '@ngxs/store';
import {finalize, take} from 'rxjs/operators';
import {RecuperarPasswordService} from 'src/app/shared/services/recuperar-password.service';
import {FillUsuario} from 'src/app/store/main.store';
import {AuthService} from '@shared/services/auth.service';
import { AuthState, LoginSSOSuccessful, LoginSuccessful } from "@store/auth.store";
import { appConfig } from "@config/config";

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  formRecuperar: FormGroup;
  formSSO: FormGroup;
  recuperado = false;
  errores: string[] = [];
  loadingRecuperacion = false;

  fase: 'login' | 'recuperar' | 'login-sso' = 'login';
  stopLoad = false;
  loggingIn = false;

  constructor(private fb: FormBuilder, private authService: AuthService, private store: Store, private router: Router, private actions$: Actions, private recuperarPasswordService: RecuperarPasswordService) {
    this.form = fb.group({
      email: [null, Validators.required],
      password: [null, Validators.required]
    });
    this.formRecuperar = fb.group({
      email: [null, [Validators.email, Validators.required]]
    });
    this.formSSO = this.fb.group({
      email: [null, [Validators.email, Validators.required]]
    });

    this.actions$.pipe(ofActionSuccessful(LoginSuccessful)).subscribe(() => this.store.dispatch(new FillUsuario(this.store.selectSnapshot(AuthState.user$))));
  }

  ngOnInit(): void {
    if (appConfig.ssoOnly && appConfig.ssoConfig) {
      this.stopLoad = true;
      this.authService.ssoConfig(appConfig.ssoConfig).subscribe((config) => {
        if (config) {
          this.store.dispatch(
            new LoginSSOSuccessful({
              ssoUrl: config.loginUrl,
              email: null
            })
          );
          window.location.replace(config.loginUrl);
        }
      });
    }
  }

  logIn(): void {
    this.loggingIn = true;
    this.errores = [];
    this.authService
      .auth(this.form.value)
      .pipe(take(1))
      .subscribe(
        (ok) => {
          this.store
            .dispatch(new LoginSuccessful(ok))
            .pipe(take(1))
            .subscribe(() => {
              this.router.navigate(['/']);
            });
        },
        (err) => {
          this.errores.push(err.error.message);
          this.loggingIn = false;
        }
      );
  }

  recuperarPassword(): void {
    this.loadingRecuperacion = true;
    this.errores = [];
    this.recuperarPasswordService
      .recuperarPassword(this.formRecuperar.value)
      .pipe(
        take(1),
        finalize(() => (this.loadingRecuperacion = false))
      )
      .subscribe(
        (ok: boolean) => {
          if (ok) {
            // TODO mostrar mensaje
            this.formRecuperar.reset();
            this.recuperado = true;
          }
        },
        (err) => {
          this.errores.push(err.error.message);
        }
      );
  }

  loginSSO(): void {
    this.loggingIn = true;
    this.errores = [];
    this.authService
      .sso(this.formSSO.value)
      .pipe(take(1))
      .subscribe(
        (ok) => {
          if (ok) {
            this.store.dispatch(new LoginSSOSuccessful({ssoUrl: ok.loginUrl, email: this.formSSO.controls.email.value}));
            window.location.replace(ok.loginUrl);
          }

          this.loggingIn = false;
        },
        (err) => {
          this.errores.push(err.error.message);
          this.loggingIn = false;
        }
      );
  }

}
