import {HttpClientModule} from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AzComponentsModule} from '@azigrene/components';
import {AzDataManagerModule} from '@azigrene/data-manager';
import {PIPES_SETTINGS_LOADER, PipesLocaleSettings, PipesLocaleSettingsLoader} from '@azigrene/pipes';
import {TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoModule, TranslocoService} from '@ngneat/transloco';
import {Store} from '@ngxs/store';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {Observable, of} from 'rxjs';
import {environment} from '@env/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from '@shared/shared.module';
import {TranslocoHttpLoader} from './transloco.loader';
import {LoginComponent} from './views/login/login.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {availableLanguages} from '@shared/config';
import {defaultAccountSettings, MainState} from '@store/main.store';
import {IndexComponent} from './views/index/index.component';
import {SeleccionMiembroComponent} from './views/seleccion-miembro/seleccion-miembro.component';
import {CoreModule} from '@core/core.module';
import {StoreModule} from '@store/store.module';
import {CambioCuentaComponent} from './views/cambio-cuenta/cambio-cuenta.component';
import {NuevoLoginComponent} from '@app/views/nuevo-login/nuevo-login.component';
import {NuevoPasswordComponent} from '@app/views/nuevo-password/nuevo-password.component';
import {SsoRedirectComponent} from "@app/views/sso-redirect/sso-redirect.component";

@Injectable({providedIn: 'root'})
export class PipesSettingsLoader implements PipesLocaleSettingsLoader {

  getSettings(): Observable<PipesLocaleSettings> {
    return of(defaultAccountSettings);
  }

}

const components = [AppComponent, LoginComponent, IndexComponent, SeleccionMiembroComponent, CambioCuentaComponent, NuevoLoginComponent, NuevoPasswordComponent, SsoRedirectComponent];

@NgModule({
  declarations: [...components],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AzComponentsModule,
    TranslocoModule,
    AngularSvgIconModule.forRoot(),
    AzDataManagerModule.forRoot({
      tableSaveStatus: true,
      tableStorageKey: 'huellacarbono-datamanager'
    }),
    SharedModule,
    CoreModule,
    StoreModule
  ],
  providers: [
    {
      provide: PIPES_SETTINGS_LOADER,
      useClass: PipesSettingsLoader
    },
    {
      provide: TRANSLOCO_LOADER,
      useClass: TranslocoHttpLoader
    },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: availableLanguages,
        defaultLang: 'es',
        fallbackLang: 'es',
        reRenderOnLangChange: true,
        prodMode: environment.production
      })
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private translate: TranslocoService, private store: Store) {
    this.translate.setDefaultLang(defaultAccountSettings.locale || 'es');
    this.translate.setActiveLang(this.store.selectSnapshot(MainState.accountSettings)?.locale || defaultAccountSettings.locale);
    translate.getTranslation();
  }

}
