import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Store} from '@ngxs/store';
import {DateUtils} from '@shared/utils';
import moment from 'moment';
import {MainState} from '@store/main.store';

@Component({
  selector: 'app-date-range-selector',
  template: `
    <ng-container *ngIf="type === 'day'">
      <ng-container *ngIf="!mandatoryRange">
        <div class="flex justify-between" *ngIf="!textBottom">
          <small
            [innerHTML]="
              _dates.end
                ? ('components.date-range-selector.has-range-end' | transloco: {start: _dates.start | localizedDate, end: _dates.end | localizedDate, days: daysInRange})
                : ('components.date-range-selector.no-range-end' | transloco: {start: _dates.start | localizedDate})
            "
            class=" block mb-1 text-xs text-gray-400"
          >
          </small>
          <a class="text-xs ml-1" *ngIf="!_hasEnd" (click)="inputRangeEnd(true)"> {{ 'components.date-range-selector.set-range-end' | transloco }}</a>
          <a class="text-xs ml-1" *ngIf="_hasEnd" (click)="inputRangeEnd(false)"> {{ 'components.date-range-selector.unset-range-end' | transloco }}</a>
        </div>
        <div class="inline-flex space-x-3 w-full">
          <div class="flex-1">
            <nz-date-picker
              *ngIf="type == 'day'"
              id="date-range-selector.range-start"
              [nzFormat]="_dateFormat"
              (ngModelChange)="_dates.start = $event; emitValue()"
              [disabled]="disabled"
              [(ngModel)]="_dates.start"
              [nzDisabledDate]="disabledStartDate"
            ></nz-date-picker>
            <nz-month-picker
              *ngIf="type == 'month'"
              id="date-range-selector.range-month-start"
              [nzFormat]="_monthFormat"
              (ngModelChange)="_dates.start = $event; emitValue()"
              [disabled]="disabled"
              [(ngModel)]="_dates.start"
              [nzDisabledDate]="disabledStartDate"
            ></nz-month-picker>
          </div>
          <div class="flex-1">
            <nz-date-picker
              *ngIf="type == 'day'"
              id="date-range-selector.range-end"
              nzPlaceHolder=""
              [nzAllowClear]="true"
              [nzFormat]="_dateFormat"
              (ngModelChange)="_dates.end = $event; emitValue()"
              [disabled]="disabled || !_hasEnd"
              [(ngModel)]="_dates.end"
              [nzDisabledDate]="disabledEndDate"
            ></nz-date-picker>
            <nz-month-picker
              *ngIf="type == 'month'"
              id="date-range-selector.range-month-end"
              [nzFormat]="_monthFormat"
              (ngModelChange)="_dates.start = $event; emitValue()"
              [disabled]="disabled"
              [(ngModel)]="_dates.start"
              [nzDisabledDate]="disabledStartDate"
            ></nz-month-picker>
          </div>
        </div>
        <div class="flex justify-between" *ngIf="textBottom">
          <small
            [innerHTML]="
              _dates.end
                ? ('components.date-range-selector.has-range-end' | transloco: {start: _dates.start | localizedDate, end: _dates.end | localizedDate, days: daysInRange})
                : ('components.date-range-selector.no-range-end' | transloco: {start: _dates.start | localizedDate})
            "
            class=" block mb-1 text-xs text-gray-400"
          >
          </small>
          <a class="text-xs ml-1" *ngIf="!_hasEnd" (click)="inputRangeEnd(true)"> {{ 'components.date-range-selector.set-range-end' | transloco }}</a>
          <a class="text-xs ml-1" *ngIf="_hasEnd" (click)="inputRangeEnd(false)"> {{ 'components.date-range-selector.unset-range-end' | transloco }}</a>
        </div>
      </ng-container>
      <ng-container *ngIf="mandatoryRange">
        <div class="inline-flex space-x-3 w-full">
          <nz-range-picker
            [nzAllowClear]="true"
            [nzFormat]="_dateFormat"
            class="flex-1"
            id="date-range-selector.range"
            [ngModel]="_dates?.start == null ? [] : [_dates.start, _dates.end]"
            (ngModelChange)="onChangeRange($event[0], $event[1])"
          >
          </nz-range-picker>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="type === 'month'">
      <div class="flex justify-between" *ngIf="!textBottom">
        <small
          *ngIf="!mandatoryRange ? _dates && _dates.start : _dates && _dates.start && _dates.end"
          [innerHTML]="
            _dates.end
              ? ('components.date-range-selector.has-range-end'
                | transloco
                  : {
                      start: moment(_dates.start).startOf(type) | localizedDate,
                      end: moment(_dates.end).endOf(type) | localizedDate,
                      days: daysInRange
                    })
              : ('components.date-range-selector.no-range-end' | transloco: {start: _dates.start | localizedDate})
          "
          class=" block mb-1 text-xs text-gray-400"
        >
        </small>
        <a class="text-xs ml-1" *ngIf="!mandatoryRange && !_hasEnd" (click)="inputRangeEnd(true)"> {{ 'components.date-range-selector.set-range-end' | transloco }}</a>
        <a class="text-xs ml-1" *ngIf="!mandatoryRange && _hasEnd" (click)="inputRangeEnd(false)"> {{ 'components.date-range-selector.unset-range-end' | transloco }}</a>
      </div>
      <div class="inline-flex space-x-3 w-full">
        <div class="flex-1">
          <nz-month-picker
            *ngIf="type == 'month'"
            id="date-range-selector.range-month-start"
            [nzFormat]="_monthFormat"
            (ngModelChange)="_dates.start = $event; emitValue()"
            [disabled]="disabled"
            [(ngModel)]="_dates.start"
            [nzDisabledDate]="disabledStartDate"
          ></nz-month-picker>
        </div>
        <div class="flex-1">
          <nz-month-picker
            *ngIf="type == 'month'"
            id="date-range-selector.range-month-end"
            [nzFormat]="_monthFormat"
            (ngModelChange)="_dates.end = $event; emitValue()"
            [disabled]="disabled"
            [(ngModel)]="_dates.end"
            [nzDisabledDate]="disabledEndDate"
          ></nz-month-picker>
        </div>
      </div>
      <div class="flex justify-between" *ngIf="textBottom">
        <small
          *ngIf="!mandatoryRange ? _dates && _dates.start : _dates && _dates.start && _dates.end"
          [innerHTML]="
            _dates.end
              ? ('components.date-range-selector.has-range-end'
                | transloco
                  : {
                      start: moment(_dates.start).startOf(type) | localizedDate,
                      end: moment(_dates.end).endOf(type) | localizedDate,
                      days: daysInRange
                    })
              : ('components.date-range-selector.no-range-end' | transloco: {start: _dates.start | localizedDate})
          "
          class=" block mb-1 text-xs text-gray-400"
        >
        </small>
        <a class="text-xs ml-1" *ngIf="!mandatoryRange && !_hasEnd" (click)="inputRangeEnd(true)"> {{ 'components.date-range-selector.set-range-end' | transloco }}</a>
        <a class="text-xs ml-1" *ngIf="!mandatoryRange && _hasEnd" (click)="inputRangeEnd(false)"> {{ 'components.date-range-selector.unset-range-end' | transloco }}</a>
      </div>
    </ng-container>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeSelectorComponent),
      multi: true
    }
  ]
})
export class DateRangeSelectorComponent implements OnInit, ControlValueAccessor {

  moment = moment;

  @Input() mandatoryRange = false;
  @Input() textBottom = false;
  @Input() type: 'month' | 'day' = 'day';
  dates: Date[] = [];
  _dates: {
    start: Date;
    end: Date;
  } = {
    start: null,
    end: null
  };
  disabled: boolean;
  _dateFormat: string;
  _monthFormat: string;
  _hasEnd = false;
  daysInRange = 0;

  onChange = (_: Date[]): void => undefined;
  onTouch = (): void => undefined;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this._dateFormat = this.store.selectSnapshot(MainState.accountSettings).nzDateFormat;
    this._monthFormat = this.store.selectSnapshot(MainState.accountSettings).monthFormat;
    if (this.mandatoryRange) {
      this._hasEnd = true;
    }
  }

  registerOnChange(fn: () => unknown): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: Date[]): void {
    this.dates = obj;
    this.populateValue(obj);
    this.calculateDaysInRange();
  }

  emitValue(): void {
    const parsedStartDate: Date = this.getParsedDate(this._dates.start);
    const parsedEndDate: Date = this.getParsedDate(this._dates.end);

    this.onChange([parsedStartDate, parsedEndDate]);
    this.calculateDaysInRange();
  }

  private calculateDaysInRange() {
    const parsedStartDate: Date = this.getParsedDate(this._dates.start);
    let parsedEndDate: Date = this.getParsedDate(this._dates.end);

    // Con esto el contador de dias en formato mes se vera correctamente
    if (this.type === 'month' && this._dates.end) {
      parsedEndDate = DateUtils.withoutTimezone(moment(this._dates.end).endOf(this.type).toDate());
    }

    this.daysInRange = DateUtils.daysInRange(parsedStartDate, parsedEndDate);
  }

  private getParsedDate(date: Date) {
    return date ? DateUtils.withoutTimezone(moment(date).startOf(this.type).toDate()) : null;
  }

  private populateValue(value) {
    if (value && value.length) {
      this._dates = {
        start: value[0],
        end: value[1]
      };
      this._hasEnd = this._dates.end != undefined;
    }
  }

  onChangeRange(start: Date, end: Date): void {
    this._dates = {
      start,
      end
    };
    this.emitValue();
  }

  inputRangeEnd(hasEndRange: boolean): void {
    if (!hasEndRange) {
      this._dates.end = null;
      this._hasEnd = false;
      this.emitValue();
    } else {
      this._hasEnd = true;
    }
  }

  disabledStartDate = (d: Date): boolean => {
    if (!this._dates) {
      return false;
    }

    if (!this._dates.end) {
      return false;
    }

    return DateUtils.isBefore(this._dates.end, d);
  };

  disabledEndDate = (d: Date): boolean => {
    if (!this._dates) {
      return false;
    }

    if (!this._dates.start) {
      return false;
    }

    return DateUtils.isBefore(d, this._dates.start);
  };

}
