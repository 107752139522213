import {environment} from '@env/environment';
import {Injectable} from '@angular/core';
import {AbstractPaginatedService} from '@app/shared/interfaces/service-classes';
import {EnumModel} from '@shared/models/enum.model';

@Injectable()
export class UnidadesUserService extends AbstractPaginatedService<EnumModel, undefined> {

  buildUrlBase(): string {
    return `${environment.api.admin.unidades.user}`;
  }

}
