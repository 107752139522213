import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Select, Store} from '@ngxs/store';
import {FillMiembro, MainState} from '@app/store/main.store';
import {PageComponent} from '@app/shared/templates/page.component';
import {MiembroModel} from '@app/shared/models/miembro.model';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-seleccion-miembro',
  templateUrl: './seleccion-miembro.component.html'
})
export class SeleccionMiembroComponent extends PageComponent implements OnInit {
  loading = true;
  miembros: MiembroModel[] = [];
  @Select(MainState.miembrosCuentasActivas) miembros$: Observable<MiembroModel[]>;

  constructor(private store: Store, private router: Router) {
    super();
    this.setPageClass('flex flex-col h-screen overflow-y-auto w-full');
  }

  ngOnInit(): void {
    this.miembros$.pipe(take(1)).subscribe(
      (miembros: MiembroModel[]) => {
        this.miembros = miembros;
        if (miembros.length === 1) {
          this.seleccionarMiembro(miembros[0]);
        } else if (!miembros.length) {
          this.router.navigateByUrl('/admin');
        }

        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  seleccionarMiembro(miembro: MiembroModel): void {
    this.store.dispatch(new FillMiembro(miembro));
    this.router.navigate([`/cuenta/${miembro.cuenta.id}`]);
  }
}
