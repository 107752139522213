import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TipoAreaOrganizativa } from '@shared/models/area-organizativa.model';
import { Observable } from "rxjs";
import { RolModel } from '../models/rol.model';

@Injectable()
export class EnumsService {

  constructor(private http: HttpClient) { }

  urlBase = environment.api.baseCore;


  getTiposAreaOrganizativa(): Observable<TipoAreaOrganizativa[]> {
    return this.http.get<TipoAreaOrganizativa[]>(this.urlBase + '/tipos-area-organizativa/all');
  }

  getRoles(): Observable<RolModel[]> {
    return this.http.get<RolModel[]>(this.urlBase + '/roles/all');
  }

}
