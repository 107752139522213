import {DataColumnType} from '@azigrene/data-manager';

export enum DynamicTablePropertiesTypeEnum {
  TEXTO = 'string',
  NUMERO = 'number',
  BINARIO = 'boolean',
  FECHA = 'date',
  FECHAHORA = 'datetime',
  LISTAVALORES = 'select'
}

export function getColumnTypeFromTipo(tipo: string): DataColumnType {
  switch (tipo) {
    case DynamicTablePropertiesTypeEnum.TEXTO:
    case DynamicTablePropertiesTypeEnum.LISTAVALORES:
      return DataColumnType.string;
    case DynamicTablePropertiesTypeEnum.BINARIO:
      return DataColumnType.boolean;
    case DynamicTablePropertiesTypeEnum.NUMERO:
      return DataColumnType.number;
    case DynamicTablePropertiesTypeEnum.FECHA:
      return DataColumnType.date;
    case DynamicTablePropertiesTypeEnum.FECHAHORA:
      return DataColumnType.datetime;
  }
}
