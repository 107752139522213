import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MainState} from '@app/store/main.store';
import {Store} from '@ngxs/store';

@Component({
  selector: 'app-cambio-cuenta',
  templateUrl: './cambio-cuenta.component.html'
})
export class CambioCuentaComponent implements OnInit {

  constructor(private router: Router, private store: Store) {}

  ngOnInit(): void {
    const miembroSeleccionado = this.store.selectSnapshot(MainState.miembro);

    setTimeout(() => this.router.navigate([`/cuenta/${miembroSeleccionado.cuenta.id}`]), 1000);
  }

}
