import {AuthService} from './services/auth.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AzComponentsModule} from '@azigrene/components';
import {AzDataManagerModule} from '@azigrene/data-manager';
import {AzPipesModule} from '@azigrene/pipes';
import {MomentModule} from 'ngx-moment';
import {AuthGuard} from '@core/guards/auth.guard';
import {TokenInterceptor} from '@core/interceptor/token.interceptor';
import {RecuperarPasswordService} from './services/recuperar-password.service';
import {LanguageService} from './services/language.service';
import {TranslocoModule} from '@ngneat/transloco';
import {FormSectionComponent, FormSpaceComponent} from './components/form-section/form-section.component';
import {InputErrorPipe} from './pipes/valid-input.pipe';
import {AzValidControlDirective, AzValidDirective} from './directives/az-valid.directive';
import {LoginRoutingService} from './services/login-routing.service';
import {MiembrosService} from './services/miembros.service';
import {BlobErrorHttpInterceptor} from '@app/core/interceptor/bloberror.interceptor';
import {LanguageInterceptor} from '@app/core/interceptor/language.interceptor';
import {CuentaMiddleware} from '@app/core/middleware/cuenta.middleware';
import {MiembroMiddleware} from '@app/core/middleware/miembro.middleware';
import {EmojiModule} from '@ctrl/ngx-emoji-mart/ngx-emoji';
import {CuentasService} from '@shared/services/cuentas.service';
import {PageHeaderComponent} from '@shared/components/page-header';
import {AzDownloaderModule} from '@azigrene/downloader';
import {CommonModule, DatePipe} from '@angular/common';
import {ImageCropFormFileComponent} from '@shared/components/image-crop-form-file/image-crop-form-file.component';
import {StepperComponent} from '@shared/components/stepper/stepper.component';
import {DialogoImportacionComponent} from '@shared/components/dialogo-importacion/dialogo-importacion.component';
import {EnumsService} from '@shared/services/enums.service';
import {DataImportExportComponent} from '@shared/components/data-import-export/data-import-export.component';
import {A11yModule} from '@angular/cdk/a11y';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {SearchEmisorComponent} from '@shared/components/search-emisor/search-emisor.component';
import {CsvCopyEmisoresComponent} from '@shared/dialogs/csv-copy-emisores/csv-copy-emisores.component';
import {SeleccionMultipleComponent} from '@shared/components/seleccion/seleccion-multiple.component';
import {SeleccionIndividualComponent} from '@shared/components/seleccion/seleccion-individual/seleccion-individual.component';
import {InvitacionesService} from '@shared/services/invitaciones.service';
import {IndexPageComponent} from '@shared/components/index-page/index-page.component';
import {IsAccountAdminDirective} from '@shared/directives/is-account-admin.directive';
import {PlanesUserService} from '@shared/services/planes-user.service';
import {CuentaFormComponent} from '@shared/forms/cuenta-form/cuenta-form.component';
import {EmisoresUserService} from '@shared/services/emisores-user.service';
import {FormFileComponent} from '@shared/components/form-file/form-file.component';
import {PasteImportComponent} from '@shared/components/paste-import/paste-import.component';
import {CodemirrorModule} from '@ctrl/ngx-codemirror';
import {DynamicFieldManagerComponent} from './components/dynamic-field-manager/dynamic-field-manager.component';
import {DynamicFieldCreatorComponent} from './components/dynamic-field-manager/modals/dynamic-field-creator/dynamic-field-creator.component';
import {DynamicFieldSelectOptionsCreatorComponent} from './components/dynamic-field-manager/modals/dynamic-field-select-options-creator/dynamic-field-select-options-creator.component';
import {DateRangeSelectorComponent} from '@shared/components/date-range-selector/date-range-selector.component';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {DefaultTiposEmisorUserService} from '@shared/services/default-tipos-emisor-user.service';
import {FuentesUserService} from '@shared/services/fuentes-user.service';
import {TiposEmisionUserService} from '@shared/services/tipos-emision-user.service';
import {DefaultFuentesEmisionUserService} from '@shared/services/default-fuentes-emision-user.service';
import {UnidadesUserService} from '@shared/services/unidades-user.service';
import {ZonasUserService} from '@shared/services/zonas-user.service';
import {DefaultFactoresEmisionUserService} from '@shared/services/default-factores-emision-user.service';
import {DynamicFieldInputsFormComponent} from '@app/shared/forms/dynamic-field-inputs-form/dynamic-field-inputs-form.component';
import {AreaOrganizativaUserService} from '@shared/services/area-organizativa-user.service';
import {MapComponent} from '@shared/components/map';
import {MapService} from '@shared/services/map.service';
import {OrigenesDatoEmisorUserService} from '@shared/services/origenes-dato-emisor-user.service';
import {AlcancesUserService} from '@shared/services/alcances-user.service';
import {FilterLinksAccessPipe} from './pipes/filter-links-access.pipe';
import {RolesCuentaMiembroCuentaService} from './services/roles-cuenta-miembro.service';
import {PermisoGuard} from '@app/core/guards/permiso.guard';
import {DisableIfNotPermissionDirective, HasAllPermissionDirective, HasAnyPermissionDirective, HasPermissionDirective} from './directives/has-permission.directive';
import {ZonasCuentaService} from './services/zonas-cuenta.service';
import { DialogoImportacionCreacionComponent } from './components/dialogo-importacion-creacion/dialogo-importacion-creacion.component';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MomentModule,
  EmojiModule,
  TranslocoModule,
  A11yModule,
  DragDropModule,
  ScrollingModule,
  NzDatePickerModule,
  /* AZ modules */
  AzDataManagerModule,
  AzComponentsModule,
  AzPipesModule,
  AzDownloaderModule
];

const services = [
  AuthService,
  RecuperarPasswordService,
  LanguageService,
  LoginRoutingService,
  MiembrosService,
  CuentasService,
  EnumsService,
  InvitacionesService,
  PlanesUserService,
  EmisoresUserService,
  DefaultTiposEmisorUserService,
  FuentesUserService,
  TiposEmisionUserService,
  DefaultFuentesEmisionUserService,
  UnidadesUserService,
  ZonasUserService,
  ZonasCuentaService,
  DefaultFactoresEmisionUserService,
  DatePipe,
  AreaOrganizativaUserService,
  MapService,
  OrigenesDatoEmisorUserService,
  AlcancesUserService,
  RolesCuentaMiembroCuentaService
];

const components = [
  FormSectionComponent,
  PageHeaderComponent,
  ImageCropFormFileComponent,
  StepperComponent,
  DialogoImportacionComponent,
  DataImportExportComponent,
  SearchEmisorComponent,
  CsvCopyEmisoresComponent,
  SeleccionMultipleComponent,
  SeleccionIndividualComponent,
  IndexPageComponent,
  FormFileComponent,
  PasteImportComponent,
  DynamicFieldManagerComponent,
  DynamicFieldCreatorComponent,
  DynamicFieldSelectOptionsCreatorComponent,
  DateRangeSelectorComponent,
  FormSpaceComponent,
  MapComponent,
  DialogoImportacionCreacionComponent
];

const forms = [CuentaFormComponent, DynamicFieldInputsFormComponent];

const pipes = [InputErrorPipe, FilterLinksAccessPipe];

const directives = [AzValidDirective, IsAccountAdminDirective, AzValidControlDirective, HasPermissionDirective, HasAllPermissionDirective, HasAnyPermissionDirective, DisableIfNotPermissionDirective];

@NgModule({
  imports: [...modules, CodemirrorModule],
  exports: [...modules, ...components, ...pipes, ...directives, ...forms],
  declarations: [...components, ...pipes, ...directives, ...forms],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true
    },
    {provide: HTTP_INTERCEPTORS, useClass: CuentaMiddleware, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: MiembroMiddleware, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true},
    AuthGuard,
    PermisoGuard,
    ...services,
    ...pipes
  ]
})
export class SharedModule {}
