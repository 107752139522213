import {Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges, TemplateRef} from '@angular/core';
import {DataPaginatedSearchRequest, PaginatedResponse, SearchRequest} from '@azigrene/searchrequest';
import {Observable} from 'rxjs';
import {AzModalConfig, AzModalRef, AzOverlayComponent} from '@azigrene/components';
import {DataFilterDeclaration, DataSelection, DataSelectionType} from '@azigrene/data-manager';
import {cloneObject, searchRequestFromJSON} from '@shared/utils';
import {SearchConfig} from '@shared/directives/search-component.directive';
import {TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-seleccion',
  templateUrl: './seleccion-individual.component.html'
})
export class SeleccionIndividualComponent<T> implements OnInit, OnChanges {

  @HostBinding('class') class = 'app-scroll-page h-full max-h-screen modal';
  @Input() globalFilter = false;
  @Input() isAdmin = false;
  @Input() dropdownTemplate: AzOverlayComponent;
  @Input() currentSelection: T;
  listItemTemplate: TemplateRef<HTMLElement>;

  previousState: {
    selection: DataSelection;
    searchRequest: SearchRequest;
    selectionCount: number;
    response: PaginatedResponse<T>;
  } = {
    selection: {type: 'empty', data: []},
    searchRequest: null,
    selectionCount: null,
    response: null
  };
  dataPaginatedSearchRequest: DataPaginatedSearchRequest<T>;
  filterDeclarations: {[id: string]: DataFilterDeclaration} = {};
  selected: T;
  selection: DataSelection;
  observable: (sr) => Observable<PaginatedResponse<T>>;
  tipoSeleccion: number;
  keyStartTranslations = 'components.search-input.';

  constructor(private translateService: TranslocoService, public ref: AzModalRef, public config: AzModalConfig) {
    const configData: SearchConfig<T> = config.data;

    if (configData) {
      if (configData.listItemTemplate) {
        this.listItemTemplate = configData.listItemTemplate;
      }

      if (configData.observable) {
        this.observable = configData.observable;
      }

      if (configData.tipoSeleccion) {
        this.tipoSeleccion = configData.tipoSeleccion;
      }

      this.dataPaginatedSearchRequest = new DataPaginatedSearchRequest<T>(
        () => this.observable(this.dataPaginatedSearchRequest.searchRequest),
        (sr) => this.observable(sr)
      );
      this.previousState.searchRequest = this.dataPaginatedSearchRequest.searchRequest;
      this.previousState.selectionCount = configData.selectionCount;
      this.previousState.response = configData.response;
      if (configData.searchRequest) {
        this.dataPaginatedSearchRequest.setSearchRequest(searchRequestFromJSON(configData.searchRequest, {paginate: true, pageSize: 50}));
        this.dataPaginatedSearchRequest.searchRequest.removeFilter(configData.idFilterCode);
        this.previousState.searchRequest = searchRequestFromJSON(configData.searchRequest, {paginate: true, pageSize: 50});
        this.previousState.searchRequest.removeFilter(configData.idFilterCode);
      }

      if (configData.selection) {
        this.previousState.selection = cloneObject(configData.selection);
        this.selection = configData.selection;
        this.onChangeSelection(this.selection);
      }
    }
  }

  ngOnInit(): void {
    this.dataPaginatedSearchRequest.load();
    this.filterDeclarations = this.config?.data?.filterDeclarations;
  }

  removeSelection(): void {
    this.selection = {type: 'empty', data: []};
    this.dataPaginatedSearchRequest.searchRequest.clearFilters(false);
    this.dataPaginatedSearchRequest.data = new PaginatedResponse<T>();
    this.dataPaginatedSearchRequest.load();
  }

  close(): void {
    this.ref.close(this.previousState);
  }

  saveSelection(): void {
    this.onChangeSelection(this.selection);
    this.ref.close({
      selection: this.selection,
      searchRequest: this.dataPaginatedSearchRequest.searchRequest,
      selectionCount: this.selection.data.length,
      response: this.dataPaginatedSearchRequest.data
    });
  }

  onChangeFilter(event: {id: string; value: any}): void {
    this.dataPaginatedSearchRequest.searchRequest.toggleFilter(event.id, event.value);
  }

  onChangeSelection(event: {type: DataSelectionType; data: any[]}): void {
    this.selection = event;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selection && changes.selection.currentValue) {
      this.selection = {...changes.selection.currentValue};
    }
  }

  onSearch(str: string): void {
    this.dataPaginatedSearchRequest.searchRequest.setSearch(str, [], true);
  }

  getHeadingText(): string {
    return this.translateService.translate(this.keyStartTranslations + 'emisores.filtro');
  }

  getSubheadingText(): string {
    return this.translateService.translate('common.emisores');
  }

}
