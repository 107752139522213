import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-image-crop-form-file',
  templateUrl: './image-crop-form-file.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageCropFormFileComponent),
      multi: true
    }
  ]
})
export class ImageCropFormFileComponent implements ControlValueAccessor {

  @Input() requiredType: ('image/jpeg' | 'image/png' | 'image/')[] = ['image/jpeg', 'image/png', 'image/'];
  @Input() value: any;
  @Output() changeImage = new EventEmitter();
  imageChangedEvent: any = '';
  croppedImageEvent: any = '';
  disabled = false;
  dragging = false;
  cropping = false;

  onChange = (_: any) => {};
  onTouch = () => {};

  writeValue(value: any): void {
    if (value) {
      this.value = value;
    } else {
      this.value = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.cropping = true;
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImageEvent = event;
  }

  imageLoaded(): void {
    // show cropper
    this.cropping = true;
  }

  cropperReady(): void {
    return;
  }

  loadImageFailed(): void {
    return;
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = true;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.imageChangedEvent.emitChanges();
    this.dragging = false;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;
  }

  onDragEnd(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;
  }

  save(): void {
    this.onChange(this.value ? this.value : null);
  }

}
