import { AfterViewInit, Component, ComponentRef, EventEmitter, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AzModalConfig, AzModalRef, AzNotifierService, ModalBaseComponent } from '@azigrene/components';
import { TranslocoService } from '@ngneat/transloco';
import { Select } from '@ngxs/store';
import { ISteps, StepperComponent } from '@shared/components/stepper/stepper.component';
import { MainState } from '@store/main.store';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ImportResult } from '../dialogo-importacion/dialogo-importacion.component';

@Component({
  selector: 'app-dialogo-importacion-creacion',
  templateUrl: './dialogo-importacion-creacion.component.html',
  styleUrls: ['./dialogo-importacion-creacion.component.scss']
})
export class DialogoImportacionCreacionComponent extends ModalBaseComponent implements OnInit, AfterViewInit {

  @ViewChild('createComponentContainer', {read: ViewContainerRef, static: false}) creationContainer: ViewContainerRef;

  @Select(MainState.theme) theme$;
  steps: ISteps = {
    upload: {
      title: this.translateService.translate('components.dialogo-importar.seleccionar-fichero'),
      canGoBack: true,
      disabled: false,
      previous: null,
      active: true,
      next: 2,
      icon: 'upload'
    },
    validacion: {
      title: this.translateService.translate('components.dialogo-importar.titulo-validacion'),
      canGoBack: true,
      disabled: false,
      previous: 1,
      next: 3,
      icon: 'document-check'
    },
    creacion: {
      title: this.translateService.translate('components.dialogo-importar.titulo-creacion'),
      canGoBack: true,
      disabled: false,
      previous: 2,
      next: 4,
      icon: 'plus-circle'
    },
    resultados: {
      title: this.translateService.translate('common.resultados'),
      canGoBack: true,
      disabled: false,
      previous: 3,
      next: null,
      icon: 'check-badge'
    }
  };

  urlBase: string;
  exitPressed = false;
  resultado: any;
  errores: ImportResult[];
  avisos: ImportResult[];
  erroresPorEmisorSinDuplicados: any[];
  errorFileProcess: string;
  showErroresAvisos: boolean;
  fileDownload: any;
  hasErrors: boolean;
  cols: any[];
  fileName = this.translateService.translate('components.dialogo-importar.archivo-no-seleccionado');
  isFileUploaded = false;
  loading = false;
  importIndex = 0;
  creationIndex = 0;
  creationComponentRef: ComponentRef<any>;
  import$: (file, formato?) => Observable<any>;
  preImport$: (file, formato?) => Observable<any>;
  @ViewChild('file', {static: true}) file;
  @ViewChild('stepper', {static: true}) stepper: StepperComponent;
  @ViewChild('dropContainer', {static: true}) dropContainer;

  rowsUpdatedEvent: EventEmitter<boolean>;

  ngOnInit(): void {
    this.import$ = this.config.data.import$;
    this.preImport$ = this.config.data.preImport$;
    this.rowsUpdatedEvent = this.config.data?.rowsUpdatedEvent;
    this.urlBase = this.config.data.urlBase;
    this.cols = [
      {field: 'fila', header: this.translateService.translate('common.fila')},
      {field: 'operacion', header: this.translateService.translate('common.operacion')},
      {field: 'errores', header: this.translateService.translate('common.errores')},
      {field: 'avisos', header: this.translateService.translate('common.avisos')}
    ];
  }

  constructor(
    public config: AzModalConfig,
    public ref: AzModalRef,
    private translateService: TranslocoService,
    private notifierService: AzNotifierService,
  ) {
    super(config, ref);
  }

  ngAfterViewInit(): void {
    if (this.dropContainer) {
      this.dropContainer.nativeElement.ondragover = (evt) => {
        evt.preventDefault();
      };

      this.dropContainer.ondrop = (evt) => {
        this.file.files = evt.dataTransfer.files;
        evt.preventDefault();
      };
    }
  }

  show(): void {
    this.exitPressed = false;
  }

  hide(): void {
    this.exitPressed = true;
  }

  onNextStep(step: {from: string; to: string}): void {
    switch (step.from) {
      case 'upload':
        if (this.file) {
          this.preImportCheck();
          this.stepper.activeStep = 'validacion';
          this.stepper.nextEnabled = true;
        }

        break;
      case 'validacion':
      case 'creacion':
        if (this.file) {
          this.import();
          this.stepper.activeStep = 'resultados';
          this.stepper.nextEnabled = false;
        }

        break;
    }

    this.stepper.previousEnabled = false;
  }

  onPrevious(step: {from: string; to: string}): void {
    switch (step.from) {
      case 'validacion':
        this.stepper.activeStep = 'upload';
        break;
    }
  }

  setFile(file): void {
    if (file) {
      this.file = file;
      this.fileName = file.name.replace(/.*[\/\\]/, '');
      if (file.value !== '') {
        this.isFileUploaded = true;
        this.stepper.nextEnabled = true;
      } else {
        this.isFileUploaded = false;
      }
    }
  }

  preImportCheck(): void {
    if (this.file) {
      this.loading = true;
      const nombreFichero = this.file.name;
      const formato: string = nombreFichero.substring(nombreFichero.lastIndexOf('.') + 1, nombreFichero.length).toUpperCase();

      this.preImport$(this.file, formato)
        .pipe(take(1))
        .subscribe(
          (results: any) => {
            this.fileDownload = results.fileDownload;
            this.resultado = {
              validos: results.filter((result) => result.itemResultado.errores.length === 0),
              erroneos: results.filter((result) => result.itemResultado.errores.length > 0)
            };
            this.errores = this.resultado.erroneos.map((resultadoErroneo) => resultadoErroneo.itemResultado);

            const emisoresUnicos = new Set();
            this.erroresPorEmisorSinDuplicados = this.resultado.erroneos.filter((resultadoErroneo) => {
              if(resultadoErroneo.itemResultado.errores.filter((error) => error.campoId == 'emisor').length > 0) {
                const emisor = resultadoErroneo.request.emisor;
                const isDuplicate = emisoresUnicos.has(emisor);
                emisoresUnicos.add(emisor);
                return !isDuplicate;
              }
              return false;
            });

            if (this.erroresPorEmisorSinDuplicados.length === 0 && this.file) {
              this.import();
              this.stepper.activeStep = 'resultados';
              this.stepper.saveActiveStepIndex();
            } else {
              this.hasErrors = true;
              this.loading = false;
            }

            this.stepper.nextEnabled = true;
          },
          (error) => {
            this.notifierService.showBackendError(error.error);
            this.loading = false;
          }
        );
    }
  }

  createEmisores(): void {
    this.stepper.activeStep = 'creacion';
    this.stepper.nextEnabled = true;
    this.stepper.saveActiveStepIndex();

    setTimeout(() => {
      if (this.config.data.creationComponent && this.creationContainer) {
        this.setupCreationComponent();
      }
    }, 0);
  }

  private setupCreationComponent() {
    const {creationComponent, creationProperty} = this.config.data;

    this.creationContainer.clear();
    this.creationComponentRef = this.creationContainer.createComponent(creationComponent);

    if (creationProperty) {
      const injectedData = this.erroresPorEmisorSinDuplicados[this.creationIndex].request[creationProperty];

      this.creationComponentRef.instance.injectedData = injectedData;
    }

    if (this.creationComponentRef.instance.onSave$) {
      this.creationComponentRef.instance.onSave$.unsubscribe();
      this.creationComponentRef.instance.onSave$.pipe(take(1)).subscribe(() => {
        this.nextCreate();
      });
    }
  }

  nextCreate(): void {
    if (this.creationIndex === this.erroresPorEmisorSinDuplicados.length - 1) {
      this.onNextStep({from: 'creacion', to: 'import'});
      this.stepper.saveActiveStepIndex();
    }

    this.creationIndex += 1;
    this.setupCreationComponent();
  }

  create(): void {
    this.creationComponentRef.instance.save();
    this.creationComponentRef.instance.onCreateSuccessful.pipe(take(1)).subscribe(() => this.nextCreate());
  }

  import(): void {
    if (this.file) {
      this.loading = true;
      const nombreFichero = this.file.name;
      const formato: string = nombreFichero.substring(nombreFichero.lastIndexOf('.') + 1, nombreFichero.length).toUpperCase();

      this.import$(this.file, formato)
        .pipe(take(1))
        .subscribe(
          (results: any) => {
            this.resultado = results;
            this.fileDownload = results.fileDownload;
            if (results.errorFileProcess != null) {
              this.showErroresAvisos = false;
              this.errorFileProcess = results.errorFileProcess;
            } else {
              this.showErroresAvisos = true;
              this.errores = this.resultsToImportResults(results, (value) => value.operacion === 'ERROR');
              this.avisos = this.resultsToImportResults(results, (value) => value.avisos.length > 0);
            }

            this.hasErrors = (this.errorFileProcess != null || this.errores?.length || this.avisos?.length) && (formato.toUpperCase() === 'XLS' || formato.toUpperCase() === 'XLSX');
            this.loading = false;
            this.stepper.nextEnabled = true;
            this.rowsUpdatedEvent?.emit((this.resultado?.insertados || 0) + (this.resultado?.actualizados || 0) > 0);
          },
          (error) => {
            this.notifierService.showBackendError(error.error);
            this.loading = false;
          }
        );
    }
  }

  resultsToImportResults(results: any, filterFunction: (value) => boolean): ImportResult[] {
    return results.resultado
      .filter((value) => filterFunction(value))
      .map((row) => ({
          fila: row.fila,
          operacion: row.operacion,
          errores: row.errores,
          avisos: row.avisos,
          campo: row.campo,
          mensaje: row.mensaje
        }));
  }

  downloadFile(): void {
    const binaryString = window.atob(this.fileDownload);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    saveAs(new Blob([bytes], {type: 'application/octet-stream'}), this.translateService.translate('common.resultados') + '_' + this.fileName);
  }

}
