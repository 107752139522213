import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { AuthState, LoginSuccessful } from "@store/auth.store";
import { FillUsuario } from "@store/main.store";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-sso-redirect',
  template: ``,
  styles: []
})
export class SsoRedirectComponent implements OnInit, OnDestroy {

  subscriptions$ = new Subscription();
  constructor(private activatedRoute: ActivatedRoute, private store: Store, private actions$: Actions, private router: Router) {
    const loginSuccessful$ = this.actions$.pipe(ofActionSuccessful(LoginSuccessful)).subscribe(() => {
      this.store.dispatch(new FillUsuario(this.store.selectSnapshot(AuthState.user$)));
      this.router.navigate(['seleccionar-cuenta']);
    });

    this.subscriptions$.add(loginSuccessful$);
  }

  ngOnInit(): void {
    const queryParams$ = this.activatedRoute.queryParams.subscribe(params => {
      if (params.response) {
        this.store.dispatch(new LoginSuccessful({...JSON.parse(params.response), sso: true}));
      }
    });

    this.subscriptions$.add(queryParams$);
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

}
