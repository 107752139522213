import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '../../store/auth.store';

@Injectable()
export class AuthGuard  {

  constructor(private store: Store, private router: Router) {
  }

  canActivate(): boolean {
    if (!this.store.selectSnapshot(AuthState.token$)) {
      const redirectUrl = decodeURIComponent(window.location.pathname);
      const redirectParams = decodeURIComponent(window.location.search).replace('?', '');

      if (!redirectUrl.includes('/login') && redirectUrl !== '/') {
        this.router.navigate(['/login'], {
          queryParams: {
            redirect_url: redirectUrl,
            redirect_params: redirectParams
          }
        });
      } else {
        this.router.navigate(['/login']);
      }

      return false;
    }

    return true;
  }

}
