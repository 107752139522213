import {environment} from '@env/environment';
import {Injectable} from '@angular/core';
import {AbstractPaginatedService} from '@app/shared/interfaces/service-classes';
import {FuenteModel} from '@shared/models/fuente.model';

@Injectable()
export class FuentesUserService extends AbstractPaginatedService<FuenteModel, undefined> {

  buildUrlBase(): string {
    return `${environment.api.admin.fuentes.user}`;
  }

}
