import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@core/guards/auth.guard';
import {CambioCuentaComponent} from './views/cambio-cuenta/cambio-cuenta.component';
import {IndexComponent} from './views/index/index.component';
import {LoginComponent} from './views/login/login.component';
import {SeleccionMiembroComponent} from './views/seleccion-miembro/seleccion-miembro.component';
import {AdminGuard} from '@core/guards/admin.guard';
import {NuevoLoginComponent} from '@app/views/nuevo-login/nuevo-login.component';
import {NuevoPasswordComponent} from '@app/views/nuevo-password/nuevo-password.component';
import {SsoRedirectComponent} from "@app/views/sso-redirect/sso-redirect.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'sso-redirect',
    component: SsoRedirectComponent
  },
  {
    path: 'nuevo-login/:token',
    component: NuevoLoginComponent
  },
  {
    path: 'recuperar-password/:token',
    component: NuevoPasswordComponent
  },
  {
    path: 'cambio-cuenta',
    component: CambioCuentaComponent
  },
  {
    path: '',
    component: IndexComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'seleccionar-cuenta',
        component: SeleccionMiembroComponent
      },
      {
        path: 'admin',
        canActivate: [AdminGuard],
        loadChildren: () => import('./modules/back-office/back-office.module').then((m) => m.BackOfficeModule)
      },
      {
        path: 'cuenta',
        loadChildren: () => import('./modules/cuenta/cuenta.module').then((m) => m.CuentaModule)
      },
      {
        path: 'user',
        loadChildren: () => import('./modules/cuenta/modules/panel-usuario/panel-usuario.module').then((m) => m.PanelUsuarioModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
