import {DataColumnDeclaration, DataColumnType} from '@azigrene/data-manager';

export const dynamicTableCreatorColumns: DataColumnDeclaration[] = [
  {
    field: 'edit',
    name: '',
    alwaysVisible: true,
    type: DataColumnType.custom,
    fixedWidth: 35
  },
  {
    field: 'delete',
    name: '',
    alwaysVisible: true,
    type: DataColumnType.custom,
    fixedWidth: 35
  },
  {
    field: 'nombre',
    name: 'common.nombre',
    alwaysVisible: true
  },
  {
    field: 'tipo',
    name: 'common.tipo',
    alwaysVisible: true,
    type: DataColumnType.custom
  }
];
