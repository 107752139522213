import { Component, Directive, HostBinding, OnInit } from '@angular/core';

@Directive()
export class PageComponent implements OnInit {
  @HostBinding('class') class = 'flex flex-col overflow-y-auto h-full w-full';

  constructor() {}

  setPageClass(customClass: string) {
    if (customClass) {
      this.class = customClass;
    }
  }

  ngOnInit() {}
}

@Component({
  selector: 'app-scroll-page',
  template: ``,
  styles: [],
})
export class ScrollPageComponent implements OnInit {
  @HostBinding('class') class = 'app-scroll-page h-0';

  constructor() {}

  setPageClass(customClass: string) {
    if (customClass) {
      this.class = customClass;
    }
  }

  ngOnInit() {}
}

export interface QueryParamsSRComponent {
  proccesQueryParams();
}
export interface DataManagerViewsComponent {
  onChangeView();
}
