import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, first, flatMap } from 'rxjs/operators';
import { AuthState, LogoutAction } from '../../store/auth.store';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  redirectUrl = '';
  redirectParams = '';

  constructor(private store: Store, private router: Router) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.store.select(AuthState.token$).pipe(
      first(),
      flatMap(token => {
        this.redirectUrl = decodeURIComponent(window.location.pathname);
        this.redirectParams = decodeURIComponent(window.location.search);

        let authReq = token
          ? token
            ? request.clone({
              setHeaders: { Authorization: 'Bearer ' + token }
            })
            : request
          : request;

        authReq = authReq.clone({ withCredentials: true });

        return next.handle(authReq);
      }),
      catchError((response: any) => {
        if (response instanceof HttpErrorResponse && response.status === 401) {
          this.store.dispatch(new LogoutAction());
          const sso = this.store.selectSnapshot(AuthState.ssoConfig);

          if (sso.active) {
            window.location.replace(sso.config.ssoUrl);
          } else {
            this.router.navigate(['/login'], { queryParams: { redirect_url: this.redirectUrl, redirect_params:  this.redirectParams} });
          }
        }

        return throwError(response);
      })
    );
  }

}
