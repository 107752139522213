import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first, flatMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { MainState } from '@app/store/main.store';

@Injectable()
export class MiembroMiddleware implements HttpInterceptor {

  constructor(private store: Store) {
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.indexOf('{miembroId}') < 0) {
      return next.handle(request);
    }

    return this.store.select(MainState.miembro).pipe(
      first(),
      flatMap(miembroActual => {
        let authReq = request.clone({
          url: request.url.replace('{miembroId}', miembroActual.id.toString())
        });

        authReq = authReq.clone({withCredentials: true});

        return next.handle(authReq);
      }),
      catchError((response: any) => {
        return throwError(response);
      })
    );
  }

}
