import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AzSelectItem} from '@azigrene/components';
import {DynamicTablePropertiesTypeEnum} from '@shared/components/dynamic-field-manager/data/dynamic-table-properties-type.enum';
import {DynamicTableFieldModel} from '@shared/components/dynamic-field-manager/models/dynamic-table-property.model';
import {MainState} from '@store/main.store';
import {Store} from '@ngxs/store';
import {DateUtils, jsonClone} from '@shared/utils';
import moment from 'moment';

@Component({
  selector: 'app-dynamic-field-inputs-form',
  templateUrl: './dynamic-field-inputs-form.component.html'
})
export class DynamicFieldInputsFormComponent implements OnInit {

  isInitialized = false;
  _dynamicFields: DynamicTableFieldModel[];

  @Input() data: {[uuid: string]: unknown}[];
  @Input()
  set dynamicFields(dynamicFields: DynamicTableFieldModel[]) {
    if (this.isInitialized) {
      const dynamicFieldsUuids: string[] = dynamicFields?.map((dynamicField) => dynamicField.uuid);
      const _dynamicFieldsUuids: string[] = this._dynamicFields?.map((dynamicField) => dynamicField.uuid);

      if (!dynamicFieldsUuids.every((field) => _dynamicFieldsUuids.includes(field))) {
        this._dynamicFields = dynamicFields;
        this.setDefaultValuesIntoCamposValuesForm();
      }
    } else {
      this._dynamicFields = dynamicFields;
      if (!this.data || Object.keys(this.data).length === 0) {
        this.setDefaultValuesIntoCamposValuesForm();
      }
    }
  }
  @Output() changeInputData: EventEmitter<{[uuid: string]: unknown}> = new EventEmitter<{[p: string]: unknown}>();

  camposValuesForm: {[uuid: string]: unknown} = {};
  dynamicTablePropertiesTypeEnum = DynamicTablePropertiesTypeEnum;
  selectValuesMap: {[uuid: string]: AzSelectItem[]} = {};
  _dateFormat: string;
  _dateTimeFormat: string;

  constructor(private store: Store) {
    this._dateFormat = this.store.selectSnapshot(MainState.accountSettings).nzDateFormat;
    this._dateTimeFormat = this.store.selectSnapshot(MainState.accountSettings).nzDateTimeFormat;
  }

  ngOnInit(): void {
    this.buildMapSelect(this._dynamicFields);
    if (this.data && Object.keys(this.data).length) {
      this.camposValuesForm = jsonClone(this.data);
    }

    this.isInitialized = true;
  }

  updateCampoValue(uuid: string, value: unknown): void {
    this.camposValuesForm[uuid] = value;
    this.changeInputData.emit(this.camposValuesForm);
  }

  getCampoValue(uuid: string): unknown {
    return this.camposValuesForm[uuid];
  }

  setNullFieldsIfNotInitialized(): void {
    if(this._dynamicFields?.length) {
      this.camposValuesForm = this.camposValuesForm == null ? {} : this.camposValuesForm;
      this._dynamicFields?.filter((campo) => campo.tipo === DynamicTablePropertiesTypeEnum.BINARIO && (this.camposValuesForm[campo.uuid] === undefined || this.camposValuesForm[campo.uuid] === null)).map((c) => (this.camposValuesForm[c.uuid] = false));
      this._dynamicFields?.filter((campo) => campo.tipo !== DynamicTablePropertiesTypeEnum.BINARIO && (this.camposValuesForm[campo.uuid] === undefined || this.camposValuesForm[campo.uuid] === null)).map((c) => (this.camposValuesForm[c.uuid] = null));
    }
  }

  buildMapSelect(fields: DynamicTableFieldModel[]): void {
    fields?.forEach((field) => {
      if (field?.tipo === DynamicTablePropertiesTypeEnum.LISTAVALORES) {
        const options: AzSelectItem[] = [];

        field?.opciones?.map((option) => {
          options.push({
            label: option,
            value: option
          });
        });
        this.selectValuesMap[field.uuid] = options;
      }
    });
  }

  getParsedDate(date: Date): Date {
    return date ? DateUtils.withoutTimezone(moment(date).startOf('day').toDate()) : null;
  }

  setDefaultValuesIntoCamposValuesForm(): void {
    this.camposValuesForm = null;
    this.setNullFieldsIfNotInitialized();
    this.changeInputData.emit(this.camposValuesForm);
  }

}
