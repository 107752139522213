import {MiembroModel} from './../models/miembro.model';
import {Observable} from 'rxjs';
import {Select} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {PaginatedResponse, SearchRequest} from '@azigrene/searchrequest';
import {MainState} from '@app/store/main.store';
import {environment} from '@env/environment';
import {AbstractPaginatedService} from '@shared/interfaces/service-classes';

@Injectable()
export class MiembrosService extends AbstractPaginatedService<MiembroModel, any> {

  @Select(MainState.miembro) miembroActual$: Observable<MiembroModel>;
  buildUrlBase(): string {
    return `${environment.api.cuenta.miembros}`;
  }

  getAll(): Observable<MiembroModel[]> {
    return this.http.get<MiembroModel[]>(this.buildUrlBase() + '/all');
  }

  get(miembroId: number): Observable<MiembroModel> {
    return this.http.get<MiembroModel>(this.buildUrlBase() + '/' + miembroId + '/one');
  }

  getAllByGrupoPermisos(searchRequest: SearchRequest): Observable<PaginatedResponse<MiembroModel>> {
    return this.http.post<PaginatedResponse<MiembroModel>>(this.buildUrlBase() + '/all-grupo-permisos', searchRequest);
  }

}
