import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RolCuentaMiembroModel } from '../models/rol_cuenta_miembro.model';

@Injectable()
export class RolesCuentaMiembroCuentaService {

  urlBase = environment.api.cuenta.rolesCuentaMiembro;

  constructor(private http: HttpClient) { }

  getAllByMiembro(miembroId: number): Observable<RolCuentaMiembroModel[]> {
    return this.http.get<RolCuentaMiembroModel[]>(this.urlBase + '/miembro/' + miembroId + '/all');
  }

  update(miembroId: number, idsRolesCuenta: number[]): Observable<RolCuentaMiembroModel[]> {
    return this.http.post<RolCuentaMiembroModel[]>(this.urlBase + '/miembro/' + miembroId + '/update', { miembroId, rolesCuentaIds: idsRolesCuenta });
  }


}
