import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {Store} from '@ngxs/store';
import {MainState} from '@store/main.store';
import {RolAplicacion} from '@shared/enums/rol-aplicacion.enum';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[showIfAccountAdmin]',
  exportAs: 'showIfAccountAdmin'
})
export class IsAccountAdminDirective implements AfterViewInit {

  @Input() showIfAccountAdmin = true;

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private store: Store) {}

  ngAfterViewInit(): void {
    const parent = this.elementRef.nativeElement.parentNode;
    const miembro = this.store.selectSnapshot(MainState.miembro);

    if (miembro && miembro.rol.id !== RolAplicacion.ADMIN.id) {
      this.renderer.removeChild(parent, this.elementRef.nativeElement);
    }
  }

}
